
.documents {
  .recommended {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem 2rem;
    margin: 1.5rem 0;

    @media (min-width: theme('screens.lg')) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .others {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem 2rem;
    margin: 1.5rem 0 0;


    @media (min-width: theme('screens.lg')) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
