.inclusive {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--c_text-secondary);

  margin-bottom: -0.5rem;

  .option {
    flex: 1 1 50%;
    margin: 0.5rem 0;
  }
  label { margin-bottom: 0; }
}


@media (min-width: theme('screens.xs')) {
  .inclusive {
    flex-direction: row;
  }
}
