.arrow {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;

  .shaft {
    animation: drawShaft 200ms ease-in forwards;
  }

  .head {
    animation: drawHead 200ms 400ms linear forwards;
  }
}

@keyframes drawShaft {
  to {
    stroke-dashoffset: 48;
  }
}
@keyframes drawHead {
  to {
    stroke-dashoffset: 74;
  }
}