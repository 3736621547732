
.editPrompt {
  margin-top: 1.5rem;
  &.desktop {
    display: none;
  }
}

.reportUpsell {
  margin: 4rem 0;
}

@media (min-width: theme('screens.lg')) {
  .editPrompt.desktop {
    display: block;
  }
  .editPrompt.mobile {
    display: none;
  }
}
