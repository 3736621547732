.customizeContainer {

  .back {
    display: flex;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 400;

    svg {
      transform: rotate(-90deg);
      margin-right: 0.25rem;
    }
  }

  .content {
    background: var(--c_white);
    border-radius: 1.5rem;
    margin: 1rem 0 0;

    footer {
      flex: 0 0 auto;

      display: flex;
      justify-content: center;

      padding: 0.75rem;

      .button { 
        background: var(--c_green);
        color: var(--c_white);
        padding: .5em 1.25em;
        font-weight: 500;

        min-width: 7em;

        &.cancel {
          background: var(--c_neutral-95);
          border-color: var(--c_neutral-95);
          color: var(--c_black);
          margin-left: 0.75rem;
        }

        .desktop { display: none; }
      }
    }
  }

  &.fake {
    --shine-size: 100rem;
              
    @keyframes shine {
      0% { background-position: 0% }
      100% { background-position: var(--shine-size); }
    }

    .content footer .button {      
      color: var(--c_green);
      
      &.cancel {
        color: var(--c_neutral-95);
        background: var(--c_neutral-95);
  
        background: linear-gradient(90deg, var(--c_neutral-95) 0%, var(--c_neutral-100) 30px, var(--c_neutral-95) 40px, var(--c_neutral-95) 1000px);
        background-size: var(--shine-size) var(--shine-size);
        background-position: var(--shine-size);
        background-attachment: fixed;
        animation: shine 2s linear infinite;
      }
    }
  }
}

@media (min-width: theme('screens.sm')) { 
  .customizeContainer {
    .content footer {
      justify-content: flex-start;

      padding: 1.5rem;

      .button {
        .desktop { display: inline; }
        .mobile { display: none; }
      }
    }
  }
}
