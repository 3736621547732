
 .progress {
  display: block;
  height: 2px;

  background: var(--c_neutral-86);

  .indicator {
    display: block;
    background: var(--c_green);
    height: 100%;

    transition: width 666ms ease-in-out;
  }

  width: 100%;
}