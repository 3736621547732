.projectStats {
  --c_stats-background: var(--c_green-96);
  --c_stats-label: var(--c_text-secondary);

  background: var(--c_stats-background);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 1px solid var(--c_neutral-93);
  font-family: var(--f_open-sans);
  padding: 1rem;

  h3 {
    flex: 1 1 auto;

    font-family: var(--f_open-sans);
    font-weight: 500;
    font-size: 1.5rem;

    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
  }

  .stats {
    flex: 0 1 auto;
    display: flex;
    margin-top: 0.5rem;

    .stat {
      flex: 1 0 auto;
      min-width: 6.5rem;

      .label {
        display: block;
        font-size: 0.75rem;
        color: var(--c_stats-label);
      }

      .value {
        display: inline-block;
        padding: 0.5rem 0;
        line-height: 1.25rem;
        margin-top: 0.2rem;
      }

      &.cost {
        min-width: 5rem;
      }
    }
  }

  &.fake {

    .stats .stat,
    .stats .stat .label {
      color: transparent;
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .projectStats {
    padding: 1.5rem;
  }
}

@media (min-width: theme('screens.md')) {
  .projectStats {
    flex-direction: row;
    align-items: center;

    .stats {
      margin-top: 0;
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .projectStats {
    flex-direction: column;
    align-items: flex-start;

    .stats {
      margin-top: 0.5rem;
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .projectStats {
    flex-direction: row;
    align-items: center;

    .stats {
      margin-top: 0;
    }
  }
}