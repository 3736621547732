.section {
  border-bottom: 1px solid var(--c_neutral-93);

  font-family: var(--f_open-sans);

  padding: 1rem;

  h3 {
    font-family: var(--f_open-sans);
    font-weight: 500;
    font-size: 1.5rem;
  }
  p {
    font-family: var(--f_open-sans);
    color: var(--c_text-secondary);
    margin-bottom: 0.5rem;
  }

  .radioSet {
    .radioOption {
      display: flex;
      align-items: center;

      margin: 1rem 0;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        margin: 0.2rem 0 0 0.25rem;

        .radioDescription {
          color: var(--c_text-secondary);
        }
      }
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .section {
    padding: 1.5rem;
  }
}
