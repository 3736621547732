.infoFlag {
  display: flex;

  font-size: 1rem;
  background: var(--c_orange-96);
  color: var(--c_text-secondary);
  border-radius: 0.75rem;

  padding: 1rem 1rem;
  margin-top: 1rem;

  svg {
    flex: 0 0 auto;
    color: var(--c_orange);
    margin-right: 1rem;
  }

  strong {
    font-weight: bold;
  }
}

.infoFlag a {
  color: var(--c_orange);
}
