.uploadRow {
  @media (min-width: theme('screens.lg')) {
    grid-column-end: span 2;
  }
}

.otherFileUpload {
  border-radius: 1.5rem;
  border-style: solid;
  color: var(--c_neutral-4);
  display: inline-block;
  margin-top: 0.5rem;

  :global(.touchTarget) {
    padding: 0.875rem 1.5rem;
  }
}
