.container {
  border-bottom: 1px solid var(--c_background-secondary);
  border-top: 1px solid var(--c_background-secondary);
  margin: 1.5rem -1.5rem;
  padding: 1.5rem 0;

  @media (min-width: theme('screens.md')) {
    margin: 1.5rem -7.5rem;
  }
}

.heading {
  margin: 0 1.5rem;

  @media (min-width: theme('screens.md')) {
    margin: 0 7.5rem;
  }
}

.caseStudiesWrapper {
  position: relative;
  height: 12.5rem;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 1.5rem;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .caseStudies {
    display: flex;
    flex-direction: row;
    gap: 0.71875rem;
    position: absolute;
    padding: 0 1.5rem;

    @media (min-width: theme('screens.md')) {
      padding: 0 7.5rem;
    }

    .caseStudy {
      height: 12.5rem;
      width: 9.75rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem;
      }

      .title {
        position: absolute;
        z-index: 2;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
      }

      .gradient {
        position: absolute;
        z-index: 1;
        border-radius: 2rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, 0) 50%);
      }

      .playIcon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        color: var(--c_element-primaryWhite);
      }
    }
  }
}