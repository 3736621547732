.addressAutocompleteField {
  position: relative;
  width: 100%;

  .search {
    position: relative;
    justify-self: center;

    height: 3.5rem;
    width: 100%;
    max-width: none;

    input {
      position: relative;
      -webkit-appearance: none;
      background-color: var(--c_white);
      border-radius: 0.375rem;
      border: 1px solid var(--c_neutral-86);
      font-weight: 600;

      height: 100%;
      width: 100%;

      font-size: 1rem;
      line-height: 1.25rem;

      padding: 1.25rem 3rem 1.25rem 3rem;

      z-index: 2;

      &:focus,
      &:active {
        background: var(--c_white);
        border-color: var(--c_element-primaryBlack);
        outline: none;
      }

      &::placeholder {
        color: var(--c_neutral-65);
      }
    }

    ol {
      position: absolute;
      top: calc(100% + 0.25rem);
      left: 0;

      width: 100%;

      background: var(--c_white);

      padding: 0;
      border-radius: 0.5rem;
      border: none;

      box-shadow: inset 0px 0px 0px 1px var(--c_neutral-93), 0 1px 5px rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.08));
      overflow: hidden;

      z-index: 2;

      li>div {
        padding: 1rem 1.5rem;
        background-color: var(--c_white);

        &:focus {
          outline: none;
        }

        &:hover {
          cursor: pointer;
          border-radius: 0;
          background-color: var(--c_neutral-97);
        }
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 1rem;
    height: 1.5rem;
    width: 1.5rem;

    color: var(--c_text);

    z-index: 2;

    &.right {
      right: 1rem;
      left: inherit;
    }

    &.close {
      right: 0.5rem;
      left: inherit;

      svg {
        height: 1rem;
        width: 1rem;
        padding: 0.125rem;
        border-radius: 50%;
        background: var(--c_neutrals-500);
        color: var(--c_white);
      }
    }
  }

  .predictionStreetAddress {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25em;
    color: var(--c_element-primaryBlack);
    padding: 0;
  }

  .predictionCityAddress {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: var(--c_element-tertiaryBlack);
    padding: 0;
  }
}

.unavailable {
  h4 {
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 700;

    margin-top: 1.5rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.14em;
    font-weight: 400;
    color: var(--c_neutral-47)
  }

  ul li {
    margin-top: 0.5rem;

    color: var(--c_green);

    cursor: pointer;

  }
}


@media (min-width: theme('screens.sm')) {
  .addressAutocompleteField {
    .search {
      height: 4rem;
    }
  }

  .unavailable {
    h4 {
      margin-top: 2rem;
    }
  }
}