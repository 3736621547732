
.wrapper {
  width: 100%;
  padding: 0;
}

.inner {
  padding: 0;
  height: auto;

  :global(.calendly-inline-widget) {
    height: 100% !important;
    width: 100% !important;
    background: var(--c_white);

    @media (max-width: theme('screens.sm')) {
      height: auto !important; 

      iframe {
        min-height: 1000px !important; // this is an arbitrarily big number, we want all
                                      // the content to fit without scroll
      } 
    }
  }
}