
.fakeDetails {
  --shine-size: 100rem;
                    
  @keyframes shine {
    0% { background-position: 0% }
    100% { background-position: var(--shine-size); }
  }

  display: flex;
  flex-direction: column;

  section {
    border-bottom: 1px solid var(--c_neutral-93);

    font-family: var(--f_open-sans);

    padding: 1rem;

    h3 {
      font-family: var(--f_open-sans);
      font-weight: 500;
      font-size: 1.5rem;
      padding-bottom: 0.25rem;
      margin-bottom: 1px;

      width: 30%
    }
      
    p {
      font-family: var(--f_open-sans);
      color: var(--c_text-secondary);
      margin-bottom: 0.5em;

      
      width: 75%;
    }

    h3, p {
      color: transparent;
      border-radius: 0.5rem;
      background: var(--c_neutral-95);

      background: linear-gradient(90deg, var(--c_neutral-95) 0%, var(--c_neutral-100) 30px, var(--c_neutral-95) 40px, var(--c_neutral-95) 1000px);
      background-size: var(--shine-size) var(--shine-size);
      background-position: var(--shine-size);
      background-attachment: fixed;
      animation: shine 2s linear infinite;
    }

    .field {
      display: block;
      color: transparent;
      height: 3rem;
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .fakeDetails {
    section {
      padding: 1.5rem;
    }
  }
}
