.slideDimensions {
  height: 280px;
}

.bigUploadPrompt {
  position: relative;
  display: flex;
  height: 100%;
  background: theme('colors.lapis-fine');
  color: theme('colors.moss');

  .upload {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 0.25rem;
    font-size: 1rem;
    letter-spacing: -0.0125rem;

    .icon {
      width: 4rem;
    }
  }
}


