.projectTemplates {
  --load-transition-time: 200ms;

  header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      flex: 1 1 100%;
      margin-bottom: 1rem;
      font-family: var(--f_open-sans);
      font-size: 1.5rem;
    }
    .sorting {
      font-size: 0.75rem;
      color: var(--c_neutral-65);

      margin-left: 0.5rem;

      transition: opacity var(--load-transition-time) ease-in;

      span,
      button {
        margin-left: 0.5rem;
      }
      button:focus {
        outline: none;
      }

      .selected {
        color: var(--c_text);
      }
    }
  }

  .templates {
    margin: 0.5em 0;
    .template {
      display: flex;

      .item {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;

        background: var(--c_white);
        border-radius: 1.5rem;

        border: 2px solid var(--c_white);
        cursor: pointer;
        text-align: left;

        padding: 1rem;
        margin: 0.5rem 0;

        &:hover {
          border: 2px solid var(--c_green);
        }

        .img {
          flex: 0 0 auto;
          height: 2rem;
          width: 2rem;
          object-fit: contain;
          margin-right: 0.75rem;
        }

        .row {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .details {
              width: 100%;
              .name {
                display: block;
                font-weight: bold;
              }
              .description {
                color: var(--c_neutral-50);
                margin-top: 0.25rem;
                /* Webkit only? Support shows as all monder browsers even *with* the
                  -webkit prefix
                */
                -webkit-line-clamp: 3;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
              }
            }
          }
          .actions {
            position: relative;
            flex: 0 0 auto;

            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;

            .whyNote {
              font-size: 0.75rem;
              color: var(--c_neutral-47);

              &:hover,
              &:active {
                text-decoration: underline;
              }
            }
            .cta {
              font-size: 1.1rem;
            }
            .fakeButton {
              border-radius: 3rem;
              background: var(--c_green);
              color: var(--c_white);

              min-width: 9rem;
              padding: 0.5rem 1.5rem;

              font-size: 1.1rem;
              font-weight: 300;
              letter-spacing: 0.05rem;
              text-align: center;

              &.disabled {
                background: var(--c_neutral-85);
                color: var(--c_black);
                font-weight: 400;
              }
            }
            
            .arrow {
              position: absolute;
              top: 1.5rem;
              right: -3rem;
              transform: scaleX(-1);


              color: var(--c_orange);
              filter: drop-shadow(0 0 2px var(--c_white))
                      drop-shadow(0 0 3px var(--c_white))
                      drop-shadow(0 0 5px var(--c_white))
                      drop-shadow(0 0 10px var(--c_white));
            }
          }
          .errorFlag {
            align-self: stretch;
          }
          .infoLink {
            display: inline;

            border: none;
            outline: none;
            text-decoration: underline;

            color: var(--c_orange);
          }
        }
      }

      &.fake {
        --shine-size: 100rem;

        @keyframes shine {
          0% {
            background-position: 0%;
          }
          100% {
            background-position: var(--shine-size);
          }
        }

        .item {
          &:hover {
            border-color: var(--c_white);
          }

          .img {
            border-radius: 0.5rem;
            background: var(--c_neutral-95);

            background: linear-gradient(
              90deg,
              var(--c_neutral-95) 0%,
              var(--c_neutral-100) 30px,
              var(--c_neutral-95) 40px,
              var(--c_neutral-95) 1000px
            );
            background-size: var(--shine-size) var(--shine-size);
            background-position: var(--shine-size);
            background-attachment: fixed;
            animation: shine 2s linear infinite;
          }

          .row .content .details .name {
            color: transparent;
            width: 50%;
            border-radius: 0.5rem;
            background: var(--c_neutral-95);

            background: linear-gradient(
              90deg,
              var(--c_neutral-95) 0%,
              var(--c_neutral-100) 30px,
              var(--c_neutral-95) 40px,
              var(--c_neutral-95) 1000px
            );
            background-size: var(--shine-size) var(--shine-size);
            background-position: var(--shine-size);
            background-attachment: fixed;
            animation: shine 2s linear infinite;
          }
          .row .content .details .description,
          .row .actions .cta {
            color: transparent;
          }
        }
      }
    }

    .unavailable {
      opacity: 0.5;
      position: relative;

      .unavailableTag {
        position: absolute;
        top: 2rem;
        right: 2rem;

        border: 1px solid var(--c_black);
        background: var(--c_neutral-95);
        color: var(--c_black);
        border-radius: 3px;
        font-size: 0.85rem;
        padding: 0.25em 0.5em;
        font-weight: 500;
      }
    }
  }

  &.switching {
    header .listFilters,
    header .sorting {
      opacity: 0;
    }
  }
}

.modal p {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: theme('colors.moss');

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

.listFilters {
  --load-transition-time: 200ms;

  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;

  margin: 0.65rem -0.4rem 0.25rem;

  transition: opacity var(--load-transition-time) ease-in;

  .filter {
    border-radius: 0.5rem;
    margin: 0.4rem 0.25rem;

    background: var(--c_white);
    
    font-size: 0.75rem;

    button {
      display: flex;
      width: 100%;
      padding: 0.5rem 0.75rem;

      text-align: left;

      .filterName {
        flex: 1 1 auto;
      }
      .filterCount {
        flex: 0 0 auto;
        color: var(--c_neutral-47);
        margin-left: 0.5rem;
      }

      &:focus {
        outline: none;
      }
    }
    &.search {
      background: var(--c_neutral-93);
      
      .searchIcon {
        margin: -0.1rem -0.3rem -0.1rem 0.25rem;
        height: 1.25rem;
        color: var(--c_neutral-65);
      }
    }

    &.selected {

      button .filterName {
        font-weight: 800;
      }
    }
  }

  &.switching {
    opacity: 0;
  }
}

.searchBox {
  display: flex;
  align-items: center;
  width: 100%;

  background: var(--c_white);
  
  border-radius: 1.5rem;
  padding: 1.5rem;
  margin: 1rem 0 0.5rem;

  label {
    flex: 0 0 auto;
    margin-right: 1rem;
  }
  .input {
    flex: 1 1 auto;
  }
  .clear {
    flex: 0 0 auto;
    margin-left: 1rem;
    button {
      margin-left: auto;
      display: block;

      color: var(--c_neutral-47);

      &:hover {
        color: var(--c_text);
      }
    }
  }
}

.projectTemplates ul.templates {
  margin: 0;
}

@media (min-width: theme('screens.lg')) {
  .projectTemplates {
    header {
      h2 {
        flex-basis: auto;
        margin-bottom: 0;
      }
      .sorting {
        font-size: 1rem;
      }
    }

    .templates {
      margin: 1.25em 0;

      .template {
        margin: 1rem 0 1.5rem;

        .item {
          font-size: 1rem;
          margin: 0;
          padding: 1.5rem;

          .img {
            display: block;
            width: 3rem;
            height: 3rem;
            margin-right: 1.5rem;
          }
          .row {
            .content {
              .actions {
                .cta {
                  font-size: 1.1em;
                }
              }
            }
          }
        }
      }
    }
  }


  .listFilters {
      .filter {
      margin: 0.4rem;
      font-size: 1rem;
  
      button {
        padding: 0.5rem 1rem;

        .filterCount {
          margin-left: 1rem;
        }
      }
      &.search {
        .searchIcon {
          margin: 0 -0.25rem 0 0.75rem;
          height: 1.5rem;
        }
      }
    }
  }

  .projectTemplates ul.templates {
    margin: -0.75rem;
  }
}

@media (min-width: theme('screens.xl')) {
  .projectTemplates .templates .template .item {
    .img {
      width: 4rem;
      height: 4rem;
    }

    .row {
      .content {
        flex-direction: row;
        .details {
          .description {
            line-height: 1.3;
          }
        }
        .actions {
          flex: 0 0 13rem;
          align-items: flex-end;

          .whyNote {
            align-self: center;
          }
          .arrow {
            top: 2rem;
            right: 12rem;
            transform: scaleX(1)
          }
        }
      }
      .errorFlag {
        flex: 1 1 100%;
      }
    }
  }
  
  .searchBox {
    label {
      flex: 0 0 5.5rem;
      margin-right: 0;
    }
    .clear {
      flex: 0 1 13rem;
      margin-left: 0;
    }
  }
}
