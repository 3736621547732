.notExpiredFlag {
  display: flex;

  font-size: 0.9rem;
  background: var(--c_gold-97);
  color: var(--c_black);
  border-radius: 0.4rem;

  padding: 0.5rem 1rem;
  margin-top: 1rem;

  svg {
    flex: 0 0 auto;
    color: var(--c_orange);
    margin-right: 0.75rem;
  }
}

.notExpiredFlag a {
  color: var(--c_orange);
}
