.alerts {
  a {
    color: var(--c_green);
    &:hover {
      color: var(--c_green-23);
      text-decoration: underline;
    }
  }
}

.expandButton {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    transform: rotate(-90deg);
  }
}

.expandedActivity {
  @media (min-width: theme('screens.md')) {
    max-height: 436px;
  }
  overflow-y: auto;
}
