.timePicker {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 -1.5rem;

  @media (min-width: theme('screens.md')) {
    margin: 0 -7.5rem;
  }

  .row {
    .timeSpinner {
      color: var(--c_white);
    }

    .timeLabel {
      margin: 0 1.5rem;

      @media (min-width: theme('screens.md')) {
        margin: 0 7.5rem
      }
    }

    .timesWrapper {
      position: relative;
      margin-top: 0.5rem;
      height: 2.5rem;
    }

    .times {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
      overflow: scroll;
      position: absolute;
      width: 100%;
      padding: 0 1.5rem;

      @media (min-width: theme('screens.md')) {
        padding-left: 7.5rem;
      }
    
      scrollbar-width: none;
      
      &::-webkit-scrollbar {
        display: none;
      }

      .time {
        min-width: auto;
        padding: 0 0.875rem;
      }
    }
  }
}

.loadingWrapper {
  position: relative;
  padding: 2rem 0;
}

.nav {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.disclaimer {
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  color: var(--c_element-tertiaryBlack);
}
