.wrapper {
  background-color: var(--c_green);
  color: var(--c_white);

  .logo {
    color: var(--c_white)
  }
}

.search {
  position: relative;
  justify-self: center;

  height: 3rem;
  max-width: 34rem;
  width: 100%;
  margin: 0 auto;

  color: var(--c_black);
}

.search input {
  -webkit-appearance: none;
  position: relative;

  border: 1px solid var(--c_white);
  border-radius: 999px;

  height: 100%;
  width: 100%;
  z-index: 2;

  padding: 0 1.5rem 0 1.5rem;

  background-color: var(--c_white);

  &:focus {
    border-color: var(--c_neutral-93);
    background-color: var(--c_white);
    outline: none;
  }

  &::placeholder {
    color: var(--c_neutral-65);
  }
}

.search ol {
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;

  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  padding: 3rem 0.5rem 0.5rem 0.5rem;

  background: var(--c_white);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--c_neutral-93);
}

.search li div {
  padding: 0.25rem 0.75rem;

  &:focus {
    outline: none;
  }

  &:hover {
    border-radius: 1rem;

    background-color: var(--c_yellow-98);

    cursor: pointer;
  }
}


@media (min-width: theme('screens.lg')) {
  .search {
    height: 4rem;
  }
  .search ol {
    top: 2rem;
  }
}
