$image-margin: 2rem;
$image-width: 400px;

.hero {
  --c_map-background: var(--c_gold-97);
  --c_image-border: var(--c_white);
  --c_image-background: var(--c_green-96-10);
  --c_city-state-text: var(--c_text-secondary);
  --c_edit-property-icon: var(--c_neutral-65);
  --c_details-sub-text: var(--c_text-secondary);
  --c_details-sub-text-value: var(--c_text);

  position: relative;

  .map {
    display: none;
    visibility: hidden;

    background: var(--c_map-background);
    // border-radius: 0.5rem;
    height: 600px;
    overflow: hidden;
    width: 100%;

    canvas:focus {
      outline: none;
    }
  }
}

@media (min-width: theme('screens.md')) {
  .hero {
    .map {
      display: block;
      visibility: visible;
    }
  }
}
