@import 'stylesheets/app/fonts';

.page {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: minmax(0, 1fr);
  align-items: start;

  @media (min-width: theme('screens.md')) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: theme('screens.xl')) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  margin: 0 auto 3rem;
  padding: 0;
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (min-width: theme('screens.md')) {
    grid-template-rows: 600px auto auto auto auto;
    top: -600px;
  }

  >section {
    display: flex;
    flex-direction: column;

    .card {
      box-shadow: 0 4px 16px 0 rgba(224, 212, 200, 0.32);
      padding: 1rem;

      h2 {
        font-family: var(--f_open-sans);
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }

      h2,
      h3 {
        font-weight: normal;
      }

      .loggedInPropertyPotentialChart {
        a {
          color: var(--c_green);

          &:hover {
            color: var(--c_green-23);
            text-decoration: underline;
          }
        }
      }

      .cardTitle {
        margin-bottom: 1.5rem;

        h2 {
          margin: 0;
        }

        h3 {
          color: var(--c_text-secondary);
        }

        .comingSoon {
          color: var(--c_neutral-47);
          font-style: italic;
          display: block;
          font-size: 80%;

          @media (min-width: theme('screens.xl')) {
            display: inline;
            font-size: 100%;
          }
        }


        a {
          color: var(--c_green);

          &:hover {
            color: var(--c_green-23);
            text-decoration: underline;
          }
        }
      }

      &.noPadding {
        padding: 0;
      }

      .editLink {
        color: var(--c_green);

        &:hover {
          color: var(--c_green-23);
          text-decoration: underline;
        }
      }
    }

    &.hero {
      position: relative;
      z-index: 1;
    }

    &.details {
      grid-column: 1;
      grid-row: 1;

      @media (min-width: theme('screens.md')) {
        padding-top: 5rem;
        grid-column: 2;
        grid-row: 1 / span 2;
      }

      @media (min-width: theme('screens.xl')) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 3;
      }

      // needed for carousel not to go crazy
      // width: calc(100vw - 3rem);
      // @media (min-width: 1024px) {
      //   width: 440px;
      // }
    }

    &.helpBox {
      grid-column: 1;
      grid-row: 3;

      display: none; // Not visible in mobile.
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-radius: 1.5rem;

      padding: 2rem;

      font-size: 1.125rem;
      text-align: center;

      background: var(--c_green-95);

      @media (min-width: theme('screens.md')) {
        grid-column: 2;
        grid-row: 3;
        display: flex;
      }

      @media (min-width: theme('screens.xl')) {
        grid-column: 3;
        grid-row: 4;
      }
    }

    &.potential {
      grid-column: 1;
      grid-row: 2;

      @media (min-width: theme('screens.md')) {
        grid-row: 5;
        grid-column: 1 / span 2;
      }

      @media (min-width: theme('screens.xl')) {
        grid-column: 1 / span 2;
        grid-row: 2 / span 4;
      }

      .comparables {
        margin-top: 1.5rem;
      }
    }

    &.projects {
      grid-column: 1;
      grid-row: auto;

      @media (min-width: theme('screens.md')) {
        grid-row: 2 / span 3;
      }

      @media (min-width: theme('screens.xl')) {
        grid-column: 1 / span 3;
        grid-row: 6;
      }
    }

    &.financialOverview {
      grid-column: 1;
      grid-row: auto;

      @media (min-width: theme('screens.md')) {
        grid-row: 6;
      }

      @media (min-width: theme('screens.xl')) {
        grid-row: 7;
      }
    }


    &.contractorsAndEdit {
      grid-column: 1;
      grid-row: auto;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      @media (min-width: theme('screens.md')) {
        grid-column: 2;
        grid-row: 6;
      }

      @media (min-width: theme('screens.xl')) {
        grid-column: 2 / span 2;
        grid-row: 7;
      }
    }
  }
}

:global(body) .editProperty {
  --c_cta: var(--c_blue);
}

.untappedPotential {
  font-size: 1.5rem;
  line-height: 1.08;
  letter-spacing: -0.2px;
  text-align: center;
}

.title {
  font-size: 1.5rem;
  letter-spacing: -0.42px;
  margin-bottom: 2rem;
  align-items: center;

  svg {
    display: inline;
    margin-left: 4px;
  }
}

.propertyPlanButtonContainer {
  display: flex;
  justify-content: center;
}

.barChartCard {
  padding-top: 1.5rem;

  .barChartCardTitle {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.75rem;
  }
}

.alertTitle {
  color: var(--c_text);
}

.alertContent {
  line-height: 1.1em;
}

.suffix {
  font-size: 1rem;
}

:global(body) {
  .helpBox {
    .advisorCta {

      border-radius: 4rem;
      margin-top: 1rem;
      padding: 0.5rem 1.5rem;

      font-weight: 300;

      background: var(--c_green);
      color: var(--c_white);

      &:focus {
        outline-color: var(--c_green-60);
      }
    }
  }
}