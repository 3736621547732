.flow {
  width: 100%;

  .enter {
    z-index: 1;

    .swipe {
      transform: translateY(100%);
    }

    .none {
      opacity: 1;
    }

    .delay {
      opacity: 0;
    }

    .switch {
      opacity: 1;
    }

    &.back {
      .swipe {
        transform: translateY(-100%);
      }

      .swipeOut {
        transform: translateY(-100%);
      }

      .delay {
        opacity: 1;
      }
    }
  }

  .enter.enterActive {
    .swipe {
      transform: translateY(0);
      transition: all 300ms ease-out;
    }

    &.back {
      .swipeOut {
        transform: translateY(0);
        transition: all 300ms ease-out;
      }

      .delay {
        opacity: 1;
      }
    }

    .none {
      opacity: 1;
    }

    .delay {
      opacity: 1;
      transition: opacity 0s linear 300ms;
    }
  }

  .exit {
    background: transparent;
    z-index: 2;

    .swipe, .swipeOut {
      transform: translateY(0);

      * {
        opacity: 1;
      }
    }

    .none {
      opacity: 1;
    }

    .appear {
      display: none;
    }

    .switch {
      opacity: 0;
    }

    &.back {
      .delay {
        opacity: 0;
      }
    }
  }

  .exit.exitActive {
    .swipe, .swipeOut {
      transform: translateY(-100%);
      transition: all 300ms ease-out;

      * {
        opacity: 0;
        transition: all 300ms ease-in-out;
      }
    }

    &.back {
      .swipe {
        transform: translateY(100%);
      }

      .swipeOut {
        transform: translateY(0);
        opacity: 0;
      }
    }

    .appear {
      display: none;
    }

    .none {
      opacity: 1;
    }

    .enterAddressVideo {
      border-radius: 0px;
      transition: border-radius 300ms ease-out,
                  background-color 300ms ease-in-out,
                  opacity 300ms ease-in-out 300ms;
      opacity: 0;
      background: var(--c_neutrals-100);
      z-index:-2;

      @media (max-width: theme('screens.sm')) {
        transition: border-radius 0ms linear,
                    background-color 300ms ease-in-out,
                    opacity 300ms ease-in-out 300ms;
      }
    }

    .enterAddressInput {
      transition: all 300ms linear;
      top: 2.25rem;
      width: calc(100% - 3rem + 1px);
    
      @media (min-width: theme('screens.sm')) {
        top: 3.25rem;
        width: calc(100% - 14rem);
      }
    }

    .enterAddressSpacer {
      height: 0;
      transition: height 300ms linear;

      @media (max-width: theme('screens.sm')) {
        transition: height 0ms linear;
      }
    }
  }
}