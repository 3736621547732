.content {
  display: grid;
  grid-template: [overview] auto [plan] auto [details] auto [templates] auto / [main side] auto;
  gap: 3.76rem 3rem;

  font-family: var(--f_open-sans);

  margin: 4rem 0;

  .plan {
    grid-area: plan / main;
  }
  .templates {
    grid-area: templates / main;
  }
  .sideBar {
    grid-area: overview / side;
    .stickySleeve {
      position: sticky;
      top: 3rem;
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .header {
    margin-right: 23rem;
  }

  .content {
    grid-template: [overview plan] auto [details templates] auto / [main] 1fr [side] 24rem;

    .sideBar {
      grid-row-end: -1;
    }
  }
  header.content {
    margin: 0 0 6rem;

    h1 { 
      font-size: 2.5rem;
      font-weight: 500;
      margin: 0;
    }
    p {
      font-size: 1.5em;
      font-weight: 500;
      line-height: 1.2em;
    }
  }
}
