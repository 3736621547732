.container {
  margin: 0 auto 3rem;
  max-width: 49rem;

  section > h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  section + section {
    border-top: 1px solid theme('colors.gray.300');
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.title {
  font-size: 1.5rem;
  margin-bottom: 3rem;

  @media (min-width: theme('screens.lg')) {
    font-size: 2.25rem;
  }
}

.toggle {
  color: theme('colors.moss');
  display: block;

  & + & {
    margin-top: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
}

.imageContainer {
  align-items: center;
  background-color: theme('colors.gray.200');
  border-radius: 999px;
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 4.5rem;

  input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &:hover .image {
    opacity: 0.1;
  }
}

.icon {
  height: 2rem;
  width: 2rem;

  path {
    fill: theme('colors.white');
  }
}

.image {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
