
.subhead {
  margin-top: 0.25rem;
}

.emailInput {
  margin-top: 1.5rem;
}

.submitButton {
  margin-top: 1rem;
  width: 100%;

  svg.checkIcon {
    width: 1rem;
    height: 1rem;
  }
}

.divider {
  display: flex;
  flex-direction: row;
  margin: 1.5rem 0;
  gap: 1.5rem;
  align-items: center;

  .line {
    width: 100%;
    border-bottom: 1px solid var(--c_element-disabledBlack)
  }
}

.differentAddressButton {
  width: 100%;
}

