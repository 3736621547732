.didYouMeanSection {
  margin-top: 1rem;

  h3 {
    font-size: 1.5rem;
  }
  .suggestion {
    margin: 0.5rem 0;
  }
}

.buttonbox {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;
}

.button {
  display: block;
  text-align: left;
  font-size: 1rem;
  
  padding: 0.75rem 1.5rem;

  border-radius: 99rem;
}


@media (min-width: theme('screens.xs')) {
  .button {
    font-size: 1.125rem;
  }
}
