.content {
  --c_purchase-background: #fdf9e9;
  --c_purchase-sparkle:    #ecb730;


  font-family: var(--f_open-sans);
  color: var(--c_black);

  h1, h2, h3 {
    font-family: var(--f_open-sans);
  }
  h2 { font-size: 1.4em }
  p {
    color: var(--c_neutral-50);
    font-weight: 300;
  }

  >header {
    margin-bottom: 3em;

    h1 {
      font-size: 1.5em;
      font-weight: 500;
    }
    p {
      color: var(--c_black);
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .purchaseResponse {
    background: var(--c_purchase-background);
    border-radius: 1.5rem;
    padding: 3.5rem;

    .sparkles {
      display: block;
      margin: 0 auto 1rem;
      height: 5rem;
      width: 5rem;
      color: var(--c_purchase-sparkle);
    }
    h2 {
      margin-bottom: 1rem;
      text-align: center;
    }
    p { text-align: center; }
    p + p { margin-top: 1rem; }
  }

  .packageList {
    margin: 3rem 0 6rem;
    
  }

  header+.packageList {
    margin-top: 6rem;
  }

  >footer {
    margin-bottom: 6rem;
    .callToAction {
      margin-top: 1rem;
      flex: 1 0 auto;

      .forwardArrow {
        transform: rotate(90deg);
        display: inline-block;
        margin: -.3rem 1rem -.2rem 0;
        vertical-align: middle;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}


.stripeLogo {
  display: inline-block;
  height: 1.5em;

  &.title {
    margin-bottom: 0.2em;
  }
}
.stripeBadge {
  display: block;
  height: 2em;
  margin: 0.5em auto;

  cursor: pointer;
}

@media (min-width: theme('screens.md')) {
  .content {

    h2 { font-size: 1.5em; }

    header {
      h1 { 
        font-size: 2.5rem;
        font-weight: 500;
      }
      p {
        font-size: 1.5em;
        font-weight: 500;
        line-height: 1.2em;
        max-width: 50rem;

        margin-top: 0.75rem;
      }
    }
  }
}

.freePackageModal {
  margin-top: 1.5rem;
}
