.upload {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px; /* compensate for slick-dots positioning */
  grid-column-end: span 1;

  .carouselContainer {
    margin-top: 0.25rem;
    border-radius: 0.5rem;
  }

  :global(.slick-slider),
  :global(.slick-list),
  :global(.slick-track),
  :global(.slick-slide),
  :global(.slick-slide > div) {
    height: 100%;
  }

  :global(.slick-dots li button) {
    background-color: var(--c_green-44)!important;
  }
  :global(.slick-dots) {
    bottom: -10px!important;
    margin-bottom: 0;
  }
  // no interacting with the dots on touch devices
  // they'll serve just to let users know they can drag
  @media (hover: none) and (pointer: coarse) {
    :global(.slick-dots) {
      pointer-events: none;
    }
  }

  .textButton {
    outline: none;
  }

  .label {
    margin-bottom: 0.75rem;
    line-height: 1.1875rem;
  }

  .imgGp {
    height: 100%;
    display: flex!important;
    flex-direction: column;
  }

  .imgContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    object-fit: cover;
  }

  .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border: dashed 1px var(--c_neutral-86);;
    color: var(--c_neutral-65);
    background-color: var(--c_yellow-98);
    min-height: 151px;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c_neutral-65);
    background-color: var(--c_yellow-98);
    min-height: 4rem;

  }

  .uploadImg {
    height: 100%;
    position: relative;
    flex: 1;

    input[type=file] {
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
  }
}

.imageActions {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: var(--c_neutral-47);
}
