.propertyFeatures {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media (min-width: theme('screens.lg')) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .feature {
    display: flex;
  }

  .showAll {
    color: var(--c_green-44);
    text-align: left;;
  }
}

.saveButton {
  display: flex;
  gap: 1rem;
}

.buttonRow {
  margin-top: 1.5rem;
}
