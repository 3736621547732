// Font declarations. This file should be only be imported once per Rails route
// (additional imports will cause it to be duplicated in the compiled CSS)

@font-face {
  font-family: 'Optima';
  font-style: normal;
  src: url('fonts/OptimaLTPro-Roman/font.woff2') format('woff2'),
    url('fonts/OptimaLTPro-Roman/font.woff') format('woff');
}

@font-face {
  font-family: 'Optima';
  font-style: italic;
  src: url('fonts/OptimaLTPro-Italic/font.woff2') format('woff2'),
    url('fonts/OptimaLTPro-Italic/font.woff') format('woff');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 100;
  font-style: normal;
  src: url('fonts/ABCDiatypeMono-Thin.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 100;
  font-style: italic;
  src: url('fonts/ABCDiatypeMono-ThinItalic.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 300;
  font-style: normal;
  src: url('fonts/ABCDiatypeMono-Light.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 300;
  font-style: italic;
  src: url('fonts/ABCDiatypeMono-LightItalic.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/ABCDiatypeMono-Regular.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 400;
  font-style: italic;
  src: url('fonts/ABCDiatypeMono-RegularItalic.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 500;
  font-style: normal;
  src: url('fonts/ABCDiatypeMono-Medium.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 500;
  font-style: italic;
  src: url('fonts/ABCDiatypeMono-MediumItalic.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/ABCDiatypeMono-Bold.ttf');
}

@font-face {
  font-family: 'ABCDiatypeMono';
  font-weight: 700;
  font-style: italic;
  src: url('fonts/ABCDiatypeMono-BoldItalic.ttf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 100;
  font-style: normal;
  src: url('fonts/Moderat-Thin.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 100;
  font-style: italic;
  src: url('fonts/Moderat-ThinItalic.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 300;
  font-style: normal;
  src: url('fonts/Moderat-Light.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 300;
  font-style: italic;
  src: url('fonts/Moderat-LightItalic.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/Moderat-Regular.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 400;
  font-style: italic;
  src: url('fonts/Moderat-RegularItalic.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 500;
  font-style: normal;
  src: url('fonts/Moderat-Medium.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 500;
  font-style: italic;
  src: url('fonts/Moderat-MediumItalic.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/Moderat-Bold.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 700;
  font-style: italic;
  src: url('fonts/Moderat-BoldItalic.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 900;
  font-style: normal;
  src: url('fonts/Moderat-Black.otf');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 900;
  font-style: italic;
  src: url('fonts/Moderat-BlackItalic.otf');
}


@font-face {
  font-family: 'OpenSans';
  font-weight: 300 800;
  font-stretch: 75% 100%;
  font-style: normal;
  src: url('fonts/OpenSans-Variable.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 300 800;
  font-stretch: 75% 100%;
  font-style: italic;
  src: url('fonts/OpenSans-Italic-Variable.ttf');
}
