.alert {
  --c_alert-background: var(--c_gold-97);
  --c_alert-link: var(--c_orange);
  --c_alert-icon: var(--c_orange);
  --c_alert-content: var(--c_text-secondary); 
  
  border-radius: 2rem;
  display: flex;
  align-items: flex-start;
  grid-column: 1/-1;
  padding: 1rem;
  position: relative;

  background: var(--c_alert-background);

  strong {
    font-weight: 500;
  }

  .icon {
    height: 24px;
    width: 24px;

    color: var(--c_alert-icon);
  }

  .content {
    margin: 0 2.5rem 0 1rem;
    color: var(--c_alert-content);

    a {
      color: var(--c_alert-link);
    }
  }

  .close {
    display: none; // Remove for now until we build the logic
    position: absolute;
    right: 1.5rem;
  }
}

@media (min-width: theme('screens.sm')) {
  .alert {
    align-items: center;
    padding: 1.5rem;

    .content {
      margin-left: 1.5rem;
    }
  }
}
