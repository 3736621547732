.customizationSection {
  --c_stats-label: var(--c_text-secondary);

  .customization {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .options {
      flex: 1 1 auto;
    }

    .stats {
      flex: 0 1 auto;
      align-self: center;
      display: flex;
      margin-top: 0.5rem;

      .stat {
        flex: 1 0 auto;
        width: 6.5rem;
        .label {
          display: block;
          font-size: 0.75rem;
          color: var(--c_stats-label);
        }

        .value {
          display: inline-block;
          padding-top: 0.5rem;
          line-height: 1.25rem;
          margin-top: 0.2rem;
        }

        &.cost {
          width: 5rem;
        }
      }
    }
  }
}
  
@media (min-width: theme('screens.md')) {
  .customizationSection .customization {
    flex-direction: row;
    align-items: flex-start;

    .stats { 
      align-self: inherit;
      margin-top: 0; 
      margin-left: 3rem;
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .customizationSection .customization {
    flex-direction: column;
    align-items: stretch;

    .stats { 
      align-self: center;
      margin-top: 0.5rem; 
      margin-left: 0;
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .customizationSection .customization {
    flex-direction: row;
    align-items: flex-start;

    .stats { 
      align-self: inherit;
      margin-top: 0; 
      margin-left: 3rem;
    }
  }
}
