.button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.editButton {
  margin-left: auto;
  color: var(--c_neutral-86);
}

button.confirmButton {
  font-size: 1rem !important;
  padding: 0 1rem !important;
  border: none !important;
  background-color: var(--c_green-44) !important;
  color: var(--c_white) !important;
  width: 5.75rem;
  height: 2.25rem;
}
