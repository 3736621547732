.plan {
  --load-transition-time: 200ms;

  header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    .title {
      flex: 1 1 100%;
      margin-bottom: 0.5rem;
      font-family: var(--f_open-sans);
      font-size: 1.5rem;
    }

    .planButton {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      font-size: 1rem;
      height: 3rem;
      color: var(--c_black);
      opacity: 0.32;

      outline: none;
      transition: opacity var(--load-transition-time) ease-in;

      svg {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.25rem;
      }

      &:hover {
        opacity: 1;
      }

      .long {
        display: none;
      }
    }

    &.editMode {
      justify-content: flex-end;

      .editButton {
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0 1.5rem;
        height: 3rem;
        margin-left: 0.75rem;
        background: var(--c_green);
        color: var(--c_white);
        font-weight: 300;

        &.cancel {
          border: none;
          background: transparent;
          color: var(--c_neutral-65);
          padding: 0;
          margin: 0 0.75rem;
        }
      }
    }
  }

  .planFooter,
  .noProjectsFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 0;

    .footerButtons {
      align-self: flex-end;
      display: flex;

      .clearAll,
      .deletePlan {
        display: flex;
        margin-left: 2rem;

        color: var(--c_black);
        opacity: 0.32;
        cursor: pointer;

        transition: opacity var(--load-transition-time) ease-in;

        svg {
          margin-right: 0.25rem;
        }

        &:hover,
        &:active {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }

    .callToAction {
      flex: 1 0 auto;
      font-size: 1rem;
      line-height: 1.25em;
      font-weight: 300;
      letter-spacing: 0.015em;
      margin-top: 1em;
      padding: 0.5rem 1rem;
      border: none;
      background: var(--c_green);
      color: var(--c_white);
    }
  }

  section {
    background: var(--c_white);
    border-radius: 1.5rem;
    margin: 0.75rem 0 0;
    padding: 0 1rem;

    .projects {
      position: relative;
      display: flex;
      flex-direction: column;

      .project {
        padding: 1.5rem 0 1rem;
        border-bottom: 1px solid var(--c_neutral-85);

        .content {
          display: flex;
          position: relative;

          .img {
            flex: 0 0 auto;
            height: 2rem;
            width: 2rem;
            object-fit: contain;
          }

          .row {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            margin-left: 1rem;

            .title {
              flex: 1 1 auto;

              .name {
                display: block;
                margin-right: 1.5rem;
              }

              .addSqFt {
                font-size: 1rem;
                display: block;
                font-weight: normal;
                color: var(--c_neutral-50);
              }
            }

            .cells {
              flex: 1 1 100%;

              display: flex;
              flex-direction: column;
              margin-top: 0.5rem;
              font-size: 0.85rem;
              color: var(--c_neutral-47);

              .cell {
                display: flex;
                justify-content: space-around;
                width: 100%;
                font-family: var(--f_monospace);
                font-weight: 500;
                margin: 0.5rem 0;

                label {
                  flex: 1 1 auto;
                  letter-spacing: 0.0175em;
                  margin: 0;
                }

                .value {
                  flex: 1 1 auto;
                  text-align: right;
                  justify-content: right;
                  margin-left: 0.5rem;
                  color: var(--c_black);
                  letter-spacing: 0.0175em;
                }

                &.customize {
                  position: relative;
                  flex-direction: column;

                  justify-content: flex-end;
                  font-family: var(--f_open-sans);
                  margin-bottom: 0;

                  label {
                    text-align: right;
                    min-width: 1rem;
                    min-height: 18px;
                  }

                  .button {
                    font-size: 1rem;
                    line-height: 1.25em;
                    font-weight: 300;
                    letter-spacing: 0.015em;
                    padding: 0.5rem 1rem;
                    background: var(--c_blue);
                    border: none;
                    color: var(--c_white);
                    margin: auto 0 auto auto;

                    &.edit {
                      background: var(--c_green);
                    }
                  }

                  .arrow {
                    position: absolute;
                    top: 0.75rem;
                    right: 7.5rem;

                    color: var(--c_orange);
                    filter: drop-shadow(0 0 2px var(--c_white)) drop-shadow(0 0 3px var(--c_white)) drop-shadow(0 0 5px var(--c_white)) drop-shadow(0 0 10px var(--c_white));
                  }
                }

                &.recoup .value {
                  display: flex;
                  align-items: center;

                  svg {
                    margin: 0 0.25rem;
                    padding: 0px 2px;
                    box-sizing: content-box;
                    border-radius: 2px;

                    background: linear-gradient(231deg, var(--c_lime) -4%, var(--c_green) 51%, var(--c_lime) 104%);
                    color: var(--c_white);
                  }
                }
              }
            }
          }

          .close {
            height: 1.5rem;
            width: 1.5rem;
            line-height: 1rem;
          }

          .close {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--c_black);
            opacity: 0.32;

            cursor: pointer;

            &:hover {
              opacity: 1;
            }

            .closePlaceholder {
              display: none;
              visibility: hidden;
            }
          }
        }

        &.fake {
          --shine-size: 100rem;

          @keyframes shine {
            0% {
              background-position: 0%;
            }

            100% {
              background-position: var(--shine-size);
            }
          }

          .img {
            border-radius: 0.5rem;
            background: var(--c_neutral-95);

            background: linear-gradient(90deg,
                var(--c_neutral-95) 0%,
                var(--c_neutral-100) 30px,
                var(--c_neutral-95) 40px,
                var(--c_neutral-95) 1000px);
            background-size: var(--shine-size) var(--shine-size);
            background-position: var(--shine-size);
            background-attachment: fixed;
            animation: shine 2s linear infinite;
          }

          .content .row .title .name {
            color: transparent;
            width: 50%;
            border-radius: 0.5rem;
            background: var(--c_neutral-95);

            background: linear-gradient(90deg,
                var(--c_neutral-95) 0%,
                var(--c_neutral-100) 30px,
                var(--c_neutral-95) 40px,
                var(--c_neutral-95) 1000px);
            background-size: var(--shine-size) var(--shine-size);
            background-position: var(--shine-size);
            background-attachment: fixed;
            animation: shine 2s linear infinite;
          }

          .content .row .cells .cell .value {
            color: transparent;
          }

          .content .row .cells .cell.customize .button {
            color: var(--c_neutral-95);
            background: var(--c_neutral-95);

            background: linear-gradient(90deg,
                var(--c_neutral-95) 0%,
                var(--c_neutral-100) 30px,
                var(--c_neutral-95) 40px,
                var(--c_neutral-95) 1000px);
            background-size: var(--shine-size) var(--shine-size);
            background-position: var(--shine-size);
            background-attachment: fixed;
            animation: shine 2s linear infinite;
          }
        }
      }
    }

    .errorEntry {
      display: flex;

      background: var(--c_gold-97);
      color: var(--c_text);
      border-radius: 0.75rem;

      margin: 1rem;
      padding: 0.75rem;

      svg {
        flex: 0 0 auto;
        color: var(--c_orange);
        margin-right: 0.75rem;
      }

      &>div {
        flex: 1 1 auto;
      }
    }

    .errorEntry+.planFooter {
      padding-top: 0;
    }
  }

  .noProjects {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    background: var(--c_orange-96);

    padding: 1.5rem 1.5rem;
    margin: 1rem 0 -0.5rem;

    svg {
      height: 3rem;
      width: 3rem;
      flex: 0 0 auto;

      margin-bottom: 0.5rem;
      color: var(--c_orange);
    }

    .instructions {
      flex: 1 1 auto;
      text-align: center;

      .title {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.75rem;
        color: var(--c_orange);
      }

      .details {
        font-size: 0.95em;
        color: var(--c_text-secondary);
      }
    }
  }

  &.switching {
    header .planButton {
      opacity: 0;
    }

    .planFooter,
    .noProjectsFooter {

      .clearAll,
      .deletePlan {
        opacity: 0;
      }
    }
  }
}

@media (min-width: theme('screens.xs')) {
  .plan {
    header {
      .planButton {
        .long {
          display: block;
        }

        .short {
          display: none;
        }
      }
    }

    section .planFooter .footerButtons {

      .clearAll,
      .deletePlan {
        margin-left: 3rem;
      }
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .plan {
    header {
      justify-content: flex-start;

      .title {
        flex: 0 1 18rem;
        margin-bottom: 0;
      }

      .planButton {
        margin-left: 0.75rem;

        .long {
          display: none;
        }

        .short {
          display: block;
        }

        &.createPlan {
          flex: 1 1 auto;
        }
      }

      &.editMode {
        justify-content: flex-start;
      }

      .editButton.cancel {
        margin-right: auto;
      }
    }

    .planFooter,
    .noProjectsFooter {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-content: center;
      padding: 1.5rem 0;

      .footerButtons {
        align-self: auto;

        .clearAll,
        .deletePlan {
          margin-left: 1.5rem;
        }
      }

      .callToAction {
        margin: 0;
        flex: 0 0 auto;
        font-size: 1.1rem;
        line-height: 1.25em;
        padding: 0.75rem 1.5rem;
      }
    }

    .noProjects {
      padding: 2.5rem;
    }
  }
}

@media (min-width: theme('screens.md')) {
  .plan {
    header {
      .planButton {
        .long {
          display: block;
        }

        .short {
          display: none;
        }
      }
    }

    .planFooter,
    .noProjectsFooter {
      .footerButtons {

        .clearAll,
        .deletePlan {
          margin-left: 3rem;
        }
      }
    }

    .noProjects {
      padding: 2.5rem 5rem;

      .instructions .title {
        font-size: 1.5rem;
        font-weight: normal;
      }
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .plan {
    header {
      .planButton {
        .long {
          display: none;
        }

        .short {
          display: block;
        }
      }
    }

    .planFooter,
    .noProjectsFooter {
      padding: 1.5rem;
      flex-direction: column;

      .footerButtons {
        align-self: flex-end;

        .clearAll,
        .deletePlan {
          margin-left: 3rem;
        }
      }

      .callToAction {
        margin-top: 1rem;
      }
    }

    .noProjectsFooter {
      padding: 1.5rem 0;
    }

    section {
      padding: 0;

      .projects {
        padding: 0;

        .project {
          padding: 1.5rem 1.5rem 1rem;
          border-color: var(--c_neutral-93);

          .content {
            flex-direction: row;

            .img {
              width: 3rem;
              height: 3rem;
            }

            .row {
              flex: 1 1 auto;
              width: inherit;
              margin-left: 1.25rem;

              .title {
                font-size: 1.5rem;
              }

              .cells {
                flex: 0 0 auto;
                flex-direction: row;
                justify-content: space-between;
                width: inherit;
                margin-top: 1rem;

                .cell {
                  flex: 1 0 auto;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  margin: 0;

                  label {
                    flex: 0 0 auto;
                    display: block;
                    font-family: var(--f_open-sans);
                    font-size: 0.75rem;
                  }

                  .value {
                    flex: 0 0 auto;
                    text-align: left;
                    font-size: 1rem;
                    margin: 0.1rem 0;
                    padding: 0.5rem 0;
                  }

                  &.homeValue {
                    flex-basis: 4.5rem;
                  }

                  &.costEstimate {
                    flex-basis: 3.5rem;
                  }

                  &.recoup {
                    flex-basis: 4rem;

                    .value {
                      flex-direction: row-reverse;
                    }
                  }

                  &.customize {
                    flex: 0 0 7rem;

                    .arrow {
                      top: 2.2rem;
                      right: none;
                      left: -2.75rem;
                    }
                  }
                }
              }
            }
          }
        }

        .errorEntry {
          padding-right: 2.5rem;
          padding-left: 5rem;
        }
      }

      .noProjects {
        padding: 2rem;
        flex-direction: column;
        align-items: center;

        .img {
          margin-right: 0;
          margin-bottom: 1rem;
        }

        .instructions {
          text-align: center;
        }
      }
    }

    .noProjects {
      padding: 2.5rem;
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .plan {
    header {
      .planButton {
        .long {
          display: block;
        }

        .short {
          display: none;
        }
      }
    }

    .planFooter,
    .noProjectsFooter {
      flex-direction: row-reverse;

      .footerButtons {
        align-self: auto;
      }

      .callToAction {
        margin-top: 0;
      }
    }

    section {
      .projects .project .content .img {
        width: 4rem;
        height: 4rem;
        margin: 0 0.5rem;
      }
    }

    .noProjects {
      padding: 2.5rem 8rem;
    }
  }
}