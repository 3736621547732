.editPropertyPrompt {
  display: flex;

  border-radius: 0.75rem;
  padding: 0.75rem 1rem;

  background: var(--c_blue-95);
  color: var(--c_neutral-47);

  svg {
    flex: 0 0 auto;
    margin-right: 0.75rem;

    color: var(--c_blue);
  }
  p {
    flex: 1 1 auto;
  }

  a {
    color: var(--c_blue);
  }
}
