
.projectsTrack {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;

  .projectsTrackHeaders {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .currentValue,
    .potentialValue {
      font-size: 2.25rem;
      line-height: 1.19;
      letter-spacing: -0.2px;
    }

    .currentValue {
      color: var(--c_tangerine);
    }

    .potentialValue {
      color: var(--c_moss);
    }
  }

  .trackContainer {
    position: relative;
    width: 1.5rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 1.75rem;
    margin-right: 1.75rem;

    .track {
      background-color: var(--c_moss_bright);
      flex-grow: 1;
      pointer-events: none;
    }

    .valueAndPotentialPips,
    .projectPips {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -3px;
      right: 0;
      display: flex;
      flex-direction: column;
      pointer-events: none;
    }

    .valueAndPotentialPips {
      justify-content: space-between;
      margin-top: -8px;
      margin-bottom: -8px;
    }

    .projectPips {
      justify-content: space-around;
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    .pip {
      width: 2rem;
      height: 2rem;
      border-radius: 9999px;
      background-color: var(--c_moss);
      border: solid 4px white;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
      pointer-events: all;
    }

    .valuePip,
    .potentialPip {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: -3px;
    }

    .valuePip {
      background-color: var(--c_tangerine);
    }

    .potentialPip {
      svg {
        color: white;
        margin-top: 4px;
        margin-left: 2px;
      }
    }

    .projects {
      position: absolute;
      left: 3.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-top: 6rem;
      padding-bottom: 6rem;
      height: 100%;
      width: calc(100vw - 10rem);
      pointer-events: none;

      .project {
        pointer-events: all;

        .projectValue {
          font-size: 1.25rem;
          color: var(--c_moss);
          margin-bottom: 0;
        }

        .potentialCta {
          display: flex;
          align-items: center;
  
          .icon {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  @media (min-width: theme('screens.md')) {
    flex-direction: column-reverse;
    padding-bottom: 3rem; // space for floating projects

    .projectsTrackHeaders {
      flex-direction: row;

      .projectPotentialHeader {
        text-align: right;
      }
    }
    .trackContainer {
      flex-direction: row;
      width: auto;
      height: 1.5rem;
      margin-left: 0;
      margin-right: 0;
      margin-top: 1.75rem;
      margin-bottom: 1.75rem;

      .projects,
      .projectPips {
        padding-left: 3rem;
        padding-right: 3rem;
      }

      .projects {
        left: 0;
        top: 3.25rem;
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: row;
        height: auto;
        width: 100%;

        .project {
          width: 33.33333%;
          text-align: center;
        }
      }

      .valueAndPotentialPips,
      .projectPips {
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: row;
        top: -4px;
        left: 0;
      }

      .valueAndPotentialPips {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: -8px;
        margin-left: -8px;
      }

      .valuePip,
      .potentialPip {
        margin-top: -3px;
        margin-left: 0;
      }
    }
  }
}