.search {
  position: relative;
}

.search input {
  position: relative;
  z-index: 2;
}

.search ol {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  background: theme('colors.white');
  padding: 3rem 0.5rem 0.5rem 0.5rem;
  box-shadow: inset 0px 0px 0px 1px theme('colors.base.gray-one'), 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

.search li div {
  padding: 0.25rem 0.75rem;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: theme('colors.base.offwhite');
    border-radius: 1rem;
  }
}

.signUp input::placeholder {
  color: theme('colors.base.gray-three');
}
