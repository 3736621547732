.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signUpButton {
  margin: 1rem 0;
  width: 100%;
}

.disclaimer {
  margin: 0 1.5rem 1.5rem;
  text-align: center;
  color: var(--c_neutral-47);
  font-size: 0.75rem;
}
