.renovationDetails {
  display: flex;
  align-items: center;

  .label {
    flex-grow: 1;
  }

  .icon {
    color: var(--c_element-tertiaryBlack);
  }
}

.addDetails {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.expanded {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}