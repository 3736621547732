.button {
  border-radius: 4rem;
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;

  font-weight: 300;
  font-size: 1.125rem;

  background: var(--c_green);
  color: var(--c_white);

  &:focus {
    outline-color: var(--c_green-60);
  }
}