header .fake {
  --shine-size: 100rem;
                    
  @keyframes shine {
    0% { background-position: 0% }
    100% { background-position: var(--shine-size); }
  }

  h1 { width: 50%; }
  p { width: 100%; }

  h1, p {
    color: transparent;
    border-radius: 0.5rem;
    background: var(--c_neutral-95);

    background: linear-gradient(90deg, var(--c_neutral-95) 0%, var(--c_neutral-100) 30px, var(--c_neutral-95) 40px, var(--c_neutral-95) 1000px);
    background-size: var(--shine-size) var(--shine-size);
    background-position: var(--shine-size);
    background-attachment: fixed;
    animation: shine 2s linear infinite;  
  }
}
