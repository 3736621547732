.card {
  flex-direction: column;
  border-radius: 3rem;
  margin: 1rem 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
  }

  .body {
    padding: 1rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;

    .icon {
      border-radius: 50%;
      padding: 1.5rem;

      >* {
        width: 3rem;
        height: 3rem;
      }

      &:global(.primary), &:global(.positive) {
        color: var(--c_primary-700);
        background-color: var(--c_primary-100);
      }

      &:global(.secondary) {
        color: var(--c_secondary-700);
        background-color: var(--c_secondary-100);
      }

      &:global(.negative) {
        color: var(--c_danger-700);
        background-color: var(--c_danger-100);
      }

      &:global(.gray) {
        color: var(--c_neutrals-700);
        background-color: var(--c_neutrals-100);
      }

      &:global(.warning) {
        color: var(--c_warning-700);
        background-color: var(--c_warning-100);
      }
    }
  }
}
