.radioButton {
  --c_radio_button_unchecked: var(--c_neutral-70);
  --c_radio_button_checked: var(--c_green);
  --c_radio_button_unchecked_focus: var(--c_green);

  --c_radio_button_disabled: var(--c_neutral-85);

  /* 
    Ideally, the entire size of this button should be tied to the
    size of the radio button. This could be tied to the width/height,
    or to the font-size. From a CSS perspective, font-size makes the
    most sense, but since this isn't 'text', that may not be most
    natural to a user. I'm open to changing it in the future.
  */
  font-size: 1rem;
  display: inline-block;

  input {
    display: none;
  }

  button {
    position: relative;
    display: block;
    margin: 0;

    height: 1.5em;
    width: 1.5em;
  }
  .button,
  .check {
    display: block;
    position: absolute;
    bottom: 0;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1.5rem;
    color: var(--c_radio_button_unchecked);
  }
  .check {
    color: var(--c_green);
  }
  input:focus + button .button,
  input:focus + button .check {
    color: var(--c_green);
  }

  .check {
    display: none;
    visibility: hidden;
  }

  input:checked + button .button {
    display: none;
    visibility: hidden;
  }
  input:checked + button .check {
    display: block;
    visibility: visible;
  }

  input:disabled + button .button,
  input:disabled + button .check {
    color: var(--c_radio_button_disabled);
  }
}
