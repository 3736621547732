.exceedsLotSizeError .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .message {
    flex: 1 1 auto;
    margin-bottom: 0.5em;
  }
  .amounts {
    font-family: var(--f_monospace);
    font-size: 0.75em;
    span { display: block }

    .maximum {
      font-weight: bold;
    }
  }
}

@media (min-width: theme('screens.md')) {
  .exceedsLotSizeError .content {
    flex-direction: row;
    align-items: flex-start;
    .message { margin-bottom: 0; margin-right: 0.5em; }
    
  }
}
