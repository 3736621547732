.header {
  background: var(--c_secondary-200);
}

.cardBody {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  @media (min-width: theme('screens.sm')) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
}
