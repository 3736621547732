.removePhotos,
.addPhotos {
  backdrop-filter: blur(2px);
  background-color: rgba(3, 3, 3, 0.7);
  opacity: 0.9;

  &:hover {
    opacity: 1;
    background-color: rgb(3, 3, 3);
  }
}

.addPhotos {
  svg {
    margin-right: -3px;
  }
}
