.title {
  margin-bottom: 3rem;

  @media (min-width: theme('screens.lg')) {
    margin-right: 26rem;
  }
}

.head {
  font-size: 1.5rem;

  @media (min-width: theme('screens.lg')) {
    font-size: 2.25rem;
  }

  &:not(:first-child) {
    padding-top: 1.5em;
    margin-top: 1.5em;
    border-top: 1px solid var(--c_neutral-86);
  }
}

.subhead {
  font-size: 1rem;
  margin-top: 0.25rem;

  @media (min-width: theme('screens.lg')) {
    font-size: 1.5rem;
  }
} 

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.button, a.button, button.button,
a.button:visited, a.button:active, a.button:hover {
  background-color: theme('colors.moss');
  color: theme('colors.white');

  margin-right: 1rem;
  &:last-child { margin-right: 0; }

  &:hover {
    color: theme('colors.white');
  }
}

a.helpButton, a.helpButton:visited,
a.helpButton:active, a.helpButton:hover {
  background-color: theme('colors.tangerine');
}

a.editProfileButton, a.editProfileButton:visited,
a.editProfileButton:active, a.editProfileButton:hover {
  background-color: theme('colors.gray.200');
  border-color: theme('colors.gray.200');
  color: theme('colors.black');
  margin-left: auto;

  &:hover {
    color: theme('colors.black');
  }
}

.toggleManagingButton {
  align-self: center;
  display: flex;
  align-items: center;

  margin: 0.75rem 0;

  label {
    cursor: pointer; 
    font-size: 1.125em;
    letter-spacing: -0.02rem;
    line-height: normal;
  }
}

.propertyList {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem -1.25rem -1.25rem;

  .propertySlot {
    flex: 1 1 auto;
    max-width: 100%;
    max-height: 750px;

    transition: max-width 200ms ease-in, max-height 200ms ease-in;

    .animSleave {
      height: 100%;
      padding: 1.25rem 1.25rem 4.25rem;
    
      .propertyCard {
        opacity: 1;
        transform: scale(1);

        transition: opacity 200ms ease-in, transform 200ms ease-in;
      }
    }
    &.deleting {
      max-width: 0%;
      max-height: 0;

      .animSleave .propertyCard {
        opacity: 0; 
        transform: scale(0);
      }
    }
  }
}

@media (min-width: theme('screens.md')) {
  .propertyList {

    .propertySlot {
      max-width: 50%;
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .propertyList {

    .propertySlot {
      max-width: 33.33%;
    }
  }
}
