.propertyShare {
  background: theme('colors.white');
  border-radius: 0.5rem;
  box-shadow: 0 4px 16px 0 rgba(224, 212, 200, 0.32);
  display: flex;
  font-size: 1.5rem;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.propertyShareImage {
  border-radius: 99px;
  height: 44px;
  width: 44px;
}

.propertyShareName {
  color: theme('colors.gray.500');
  white-space: nowrap;
}

.createWait {
  min-height: auto;
}

.button {
  display: block;
  text-align: left;
  font-size: 1rem;
  
  width: 100%;
  padding: 0.75rem 1.5rem;

  border-radius: 99rem;
}


@media (min-width: theme('screens.xs')) {
  .button {
    font-size: 1.125rem;
  }
}
@media (min-width: theme('screens.lg')) {
  .mobilePropertyOverview {
    display: none;
  }
}
