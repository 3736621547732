.container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .text {
    flex-grow: 1;
    overflow: hidden;
    min-width: 0;
  }

  .iconWrapper {
    width: 3rem;
    height: 3rem;
    background: var(--c_background-secondary);
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    align-items: center;
    flex-shrink: 0;
    align-self: flex-start;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;

    svg {
      width: 1rem;
      height: 1rem;
      color: var(--c_element-primaryBlack);
    }
  }
}