.wrapperInner {
  background: transparent !important;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  
  
  .wrapperLogo {
    @media (max-width: theme('screens.sm')) {
      position: absolute;
      z-index: 4;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .videoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;

    .rounded {
      width: 100%;
      height: 100%;
      position: absolute;
      border-bottom-left-radius: 50vw;
      border-bottom-right-radius: 50vw;

      @media (min-width: theme('screens.sm')) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 50vw;
        border-top-right-radius: 50vw;
      }
    }

    video {  
      object-fit: cover;
      z-index: 0;
    }

    .gradient {
      background: linear-gradient(0, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 50%);

      @media (min-width: theme('screens.sm')) {
        background: linear-gradient(0, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 90%);
      }
    }

    .inner {
      position: relative;
      z-index: 1;
      margin: 0 .5rem;
      width: 100%;
      
      @media (min-width: theme('screens.sm')) {
        // padding: 0 7.5rem;
      }

      .copyWrapper {
        color: var(--c_white);
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        top: 50%;
        

        @media (min-width: theme('screens.sm')) {
          width: 60%;
          top: 35%;
        }

        .heading {
          margin-bottom: 0.5rem;
        }
      }
      
      .searchButtonWrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        top: 75%;
        left: 50%;
        transform: translateX(-50%); 

        @media (min-width: theme('screens.sm')) {
          display: none;
        }

        .searchButton {
          background: var(--c_primary-700);
          transform: rotate(90deg)
        }
      }
    }
  }

  .addressInputWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &.mobile {
      display: block;
      top: calc(100% - 5rem);
      width: calc(100% - 3rem);
    }

    &.desktop {
      display: none;
    }

    @media (min-width: theme('screens.sm')) {
      &.mobile {
        display: none;
      }

      &.desktop {
        display: block;
        width: 60%;
        top: 60%;
      }
    }
  }

  .spacer {
    height: 7.5rem;
    width: 100%;

    @media (min-width: theme('screens.sm')) {
      height: 0;
    }
  }
}


