.wrapper {
  .heading {
    margin-bottom: 1.5rem;
    justify-self: flex-start;
  }

  .subheading {
    margin: 0.75rem 0 0.5rem;
  }

  .search {
    width: 100%;
    position: relative;

    .input {
      input {
        padding-left: 2.5rem;
        background: var(--c_white);
        border-radius: 0.5rem;
        border: 1px solid var(--c_neutrals-400);

        &:focus {
          border: 1px solid var(--c_element-primaryBlack);
        }
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 1rem;
      height: 1rem;
      width: 1rem;

      color: var(--c_text);

      z-index: 2;
    }
  }
}