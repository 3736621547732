.propertyCard {
  position: relative;
  background: white;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 4px 16px 0 rgba(224, 212, 200, 0.32);
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: 300;
  overflow: hidden;
  padding: 0;
  transition: 0.2s all;

  .wash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    background: var(--c_green);

    pointer-events: none;

    transition: opacity 100ms ease-in;
  }
  .delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    opacity: 0;
    background: var(--c_white);
    border-radius: 2rem;
    padding: 0.25rem;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);

    pointer-events: none;

    outline: none;

    transition: opacity 100ms ease-in-out;
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgba(224, 212, 200, 0.6);
  }

  a,
  a:hover {
    color: theme('colors.black');
    text-decoration: none !important;
  }

  &.managing {
    // We can't disable anchors. The 'right' way to do it is replace
    // those anchors with spans. But that's a PITA here.
    // Blocking pointer events should be sufficient (we could disable 
    // them instead, but we want a visual effect anyway). If someone
    // tabs through the controls while we're managing and follows
    // a link, nothing bad is going to happen.
    .wash {
      opacity: 0.35;
      pointer-events: unset;
    }
    .delete {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.imageWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;

  img, object {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    display: block;
    content: "";
    /* 8:5 aspect ratio */
    padding-bottom: 62.5%;
  }
  
}

.info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 1.5rem;
}

.stats {
  align-items: center;
  display: flex;
  gap: 1.5rem;
}

.stat {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.potential {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
}

.label {
  color: theme('colors.gray.500');
  max-width: 12rem;
}

.value {
  font-size: 1.5rem;
}

.realtorStatus {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;

  .value {
    color: theme('colors.moss');
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;

    .menuLabel,
    select {
      appearance: none;
      background-color: theme('colors.gray.400');
      border-radius: 2px;
      color: theme('colors.white');
      font-size: theme('fontSize.xs');
      font-weight: 500;
      padding: 0.375rem 0.5rem;
      text-transform: uppercase;

      &.this-is-my-listing {
        background-color: theme('colors.lapis-bright');
      }
      &.i-have-a-buyer {
        background-color: theme('colors.moss');
      }
      &.im-trying-to-win-this-client {
        background-color: theme('colors.sonne');
      }
      &.i-live-here {
        background-color: theme('colors.tangerine');
      }
    }

    select {
      opacity: 0;
      position: absolute;
      text-transform: none;
      top: 0;
      left: 0;
      width: 100%;
    }

    polygon {
      fill: theme('colors.gray.300');
    }
  }
}

.actions {
  background-color: theme('colors.gray.200');
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  padding: 1rem 0.5rem;

  button,
  a {
    align-items: center;
    background-color: theme('colors.white');
    border-radius: 99px;
    color: theme('colors.lapis-bright');
    display: flex;
    gap: 0.3125rem;
    padding: 0.375rem 1rem;

    &:hover {
      color: theme('colors.lapis-bright');
      opacity: 0.8;
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;

      path,
      polygon {
        fill: currentColor;
      }
    }
  }
}

.modal section {
  overflow: visible;
  position: relative;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: -1.5rem;
}

.modalTitle {
  font-size: 1.5rem;
}

.modalForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shareLink {
  display: flex;
  gap: 0.5rem;

  button {
    background-color: theme('colors.moss');
    border-radius: 99px;
    color: theme('colors.white');
    flex-shrink: 0;
    padding: 0 1.5rem;

    &:hover {
      opacity: 0.8;
    }
  }
}

.shareInput {
  width: 100%;
}

.unedited textarea {
  &,
  &:focus,
  &:active {
    color: theme('colors.gray.400');
  }
}

.modalFooter {
  color: theme('colors.gray.500');
  text-align: center;
}
