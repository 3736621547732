.callToAction {
  --c_cta-text: var(--c_black);
  --c_cta-background: var(--c_lime);
  --c_cta-border: var(--c_lime);

  --c_cta-negative-background: #ffb7a1;

  color: var(--c_cta-text);

  display: inline-block;
  height: 42px;

  .background {
    display: block;
    color: var(--c_cta-background);
  }
  .sleeve {
    display: flex;
    align-items: center;
    margin-top: -42px;
    height: 42px;

    .icon {
      flex: 0 0 42px;

      display: flex;
      justify-content: center;
    }
    .value {
      display: block;
      flex: 1 1 auto;

      text-overflow: ellipsis;
      white-space: nowrap;

      margin: 0 19px;
    }
  }
}

.negative {
  .background {
    color: var(--c_cta-negative-background);
  }
}
