.wrapper {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 3.76rem 3rem;
  width: 100%;

  @media (min-width: theme('screens.lg')) {
    grid-template-columns: 1fr 24rem;
  }
  
  .header {
    grid-row-start: 1;
  }

  .content {
    grid-column-start: 1;
    grid-row-start: 3;

    @media (min-width: theme('screens.lg')) {
      grid-column-start: 1;
      grid-row-start: 2;
    }
  }

  .sidebar {
    grid-column-start: 1;
    grid-row-start: 2;

    @media (min-width: theme('screens.lg')) {
      grid-column-start: 2;
    }
  }
}

.buttonWithStatus {
  display: flex;
  align-items: center;
  width: 100%;
  grid-column-end: span 2;
}

button.button {
  font-size: 1.125rem;
  padding: 0.875rem 1.5rem;
  line-height: 1rem;
  background:var(--c_green-44);
  color:var(--c_white);
  font-weight: normal;

  &:disabled {
    background: var(--c_white);
  }

  &:focus {
    outline: none
  }

}

.buttonStatus {
  flex: 1;
  margin-left: 1rem;

  &.success {
    color: var(--c_green-44);
  }
  &.error {
    color:var(--c_orange-63);
  }
}

.renovationControls {
  margin-top: 1.5rem;
  display: flex;

  .renovationKind {
    margin-right: 1.5rem;
    min-width: 12rem;
  }
}

.card {
  margin: 3rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom:0;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.625rem;

    &:not(:first-child) {
      padding-top: 1.5rem;
      margin-top: 1.5rem;
      border-top: 1px solid var(--c_neutral-86);
    }

    .sample {
      color: var(--c_orange-63);
    }
  }

  h4 {
    margin-top: 0.25rem;
    color: var(--c_neutral-47);
  }

  .link {
    color: var(--c_green-44);
  }

  svg.waves {
    width: 200%;

    transform: translate(-25%, -50%);
  }

  svg.lines {
    width: 100%;
    transform: translateY(-50%);
  }

  svg.circles {
    width: 100%;
    transform: translateY(-44%);
  }
}

.shareMore {
  color: var(--c_green-44);
  display: flex
}

.dashboardNudge {  
  .head {
    margin-top: 3rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--c_black);
  }
  .sub {
    font-size: 1.25rem;
    font-family: var(--f_optima);
    margin-bottom: 1.125rem;
  }
}
