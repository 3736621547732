
.greenButton {
  background: theme('colors.moss');
  color: white;
  &:hover, &:visited, &:active {
    color: white;
  }
  &:hover {
    text-decoration: none;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
