.wrapper {
  flex-direction: column;
}

.header {
  margin-top: -2rem;
  padding-top: 2rem;
  margin-bottom: 1.5rem;

  .subheading {
    margin-top: 0.25rem;
  }
}

