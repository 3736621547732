.label {
  line-height: 1.1875rem;
  margin-bottom: 0.75rem;

  &:not(.editing) {
    color: var(--c_neutral-47);
    margin-bottom: 0.25rem;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 100%;
  margin-right: 1rem;
}

.bigValue {
  font-size: 1rem;
  line-height: 1.1875rem;

  &:not(:global(.textarea)) {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }

  &.defaultValue {
    color: var(--c_neutral-65);
  }

  .decorator {
    font-size: 1rem;
  }
}
