.sizeSection {
  .field {
    flex: 1 1 auto;
    
    display: flex;
    align-items: center;
    .input {
      flex: 0 1 10rem;
    }
    .by {
      margin: 0 1rem;
      color: var(--c_text-secondary);
    }
    .units {
      margin: 0 0.75rem;
      color: var(--c_text-secondary);
    }
  }
  .shunter {
    display: flex;
    flex-direction: column;

    .total {
      align-self: flex-end;

      margin-top: 1rem;

      .prime {
        .label {
          margin-right: 0.75rem;
          color: var(--c_text-secondary);
        }
      }

      &.invalid {
        color: var(--c_red);
        .prime {
          margin-right: 0.25rem;
        }
      }
    }
  }

  @media (min-width: theme('screens.sm')) {
    .shunter {
      flex-direction: row;
      align-items: center;

      .total {
        align-self: inherit;
        display: flex;
        flex-wrap: wrap;

        margin-top: 0;
        margin-left: 2rem;
        .prime {
          .label {
            display: none;
          }
        }
      }
    }
  }
}
