.financialOverview {
  --c_em-text: var(--c_green);
  --c_value-sub-text: var(--c_text-secondary);
  --c_equity-sub-text: var(--c_text-secondary);
  --c_equity-low-data-text: var(--c_disabled);
  --c_equity-missing-data-text: var(--c_orange-63);
  --c_detailed-cta-background: var(--c_yellow-98);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  .bulletGlyph {
    color: var(--c_em-text);
    margin-right: 0.5rem;
  }

  em {
    color: var(--c_em-text);
    font-style: normal;
  }

  .propertyValue {
    .value {
      font-size: 1.5rem;
    }
    .label {
      color: var(--c_value-sub-text);

      .break {
        display: none;
      }
    }

    .attribution {
      font-size: 0.75rem;

      a {
        color: var(--c_value-sub-text);
      }
    }
  }

  .chartContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 232px;
    margin-bottom: 0.5rem;
    position: relative;
    width: 100%;

    .equity {
      position: absolute;
      text-align: center;
      top: 50%;
      margin-top: -1em;

      font-size: 1.5rem;

      .label {
        font-size: 1rem;
        color: var(--c_equity-sub-text);
      }
    }
  }

  .recentPayment {
    align-items: center;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    text-align: center;
    margin: 1rem 0;
  }

  .detailedCTA {
    background: var(--c_detailed-cta-background);
  }

  .options {
    flex: 1 1 auto;

    .subTitle {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .option {
      align-items: center;
      display: flex;
      margin-bottom: 1.5rem;

      a {
        color: var(--c_green);

        &:hover {
          color: var(--c_green-23);
          text-decoration: underline;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: flex-start;
    justify-self: flex-end;
  }
}

.lowData {
  color: var(--c_equity-low-data-text);
}
.missingData {
  color: var(--c_equity-missing-data-text);
}

@media (min-width: theme('screens.lg')) {
  .financialOverview {
    .options {
      flex: 1 1 auto;

      .subTitle {
        margin-bottom: 2rem;
      }
    }

    .action {
      justify-content: center;

      .cta {
        padding: 1rem 1rem;
      }
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .financialOverview {
    .propertyValue {
      left: 0;
      position: absolute;
      top: 0;
      z-index: 1;

      .label {
        .break {
          display: inline;
        }
      }
    }
  }
}
