.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(circle at bottom, var(--c_moss-900), var(--c_element-primaryBlack));
  width: 100%;
  height: 100vh;
  position: absolute;
}

.grid {
  display: grid;
  height: 100%;
  width: 100%;

  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);

  &.allowOverflow {
    grid-template-rows: 1fr;
    height: auto;
  }

  @media (min-width: theme('screens.sm')) {
    width: auto;
    grid-template-columns: 5rem 1fr;
    grid-template-rows: auto minmax(0, 1fr) auto;
    margin-left: -5rem;

    &.allowOverflow {
      grid-template-rows: auto minmax(0, 1fr) auto;
      height: 100%;
    }
  }
}

.logoWrapper {
  display: none;

  @media (min-width: theme('screens.sm')) {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2;
    grid-row: 1;
  }

  .logo {
    margin: 2.5rem 0 1.5rem;
    color: var(--c_white);
    height: 2.5rem;
  }
}

.nav {
  display: none;
  align-self: center;

  @media (min-width: theme('screens.sm')) {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
  }

  .navButton {
    &:global(.disabled) {
      background-color: var(--c_white);
      opacity: 0.3;
    }
  }
}

.inner {
  @media (min-width: theme('screens.sm')) {
    grid-column: 2;
    grid-row: 2;
  }

  position: relative;
  width: 100%;
  font-family: var(--f_open-sans);
  overflow: hidden;
  overflow-y: clip;
  display: flex;
  flex-direction: column;

  .close {
    margin-bottom: 1rem;
    display: block;
  }

  .footerWrapper {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;

    .footerButton {
      width: 100%;
    }
  }

  @media (min-width: theme('screens.sm')) {
    border-radius: 2rem;
    width: 32rem;

    .footerWrapper {
      width: 15rem;
      margin: 0 auto;
    }
  }

  @media (min-width: theme('screens.md')) {
    width: 45rem;

    .close {
      margin-top: -1.5rem;
      margin-left: -6rem;
    }
  }
}

.scroll {
  background-color: var(--c_neutrals-100);
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 2rem 1.5rem;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: theme('screens.md')) {
    padding: 3rem 7.5rem;
  }

  &.leaveFooterSpace {
    padding-bottom: 6rem;
  }

  &.allowOverflow {
    @media (max-width: theme('screens.sm')) {
      overflow: visible;
    }
  }
}

.progress {
  background-color: var(--c_neutrals-100);
  height: 0.25rem;
  flex-shrink: 0;
  z-index: 10;

  &.allowOverflow {
    @media (max-width: theme('screens.sm')) {
      position: fixed;
      top: 0;
      z-index: 10;
    }
  }

  .indicator {
    background: var(--c_primary-700);

    &.disabled {
      background: var(--c_neutrals-300);
    }

    &.error {
      background: var(--c_danger-700);
    }
  }
}

.spacer {
  display: none;

  @media (min-width: theme('screens.sm')) {
    grid-column: 2;
    grid-row: 3;
    display: block;
    height: 3rem;
    flex-shrink: 0;
  }
}