.subheading {
  margin-top: 0.25rem;
}

.wrapper { 
  min-height: 100vh;
}

.sso {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 1rem 0;

  .button {
    width: 100%;
    background: var(--c_neutrals-200);
    color: var(--c_element-primaryBlack)
  }

  svg.ssoIcon {
    height: 2.5rem;
    width: 2.5rem;
    margin: -0.5rem;
  }
}

.divider {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  margin: 1rem 0;

  .line {
    width: 100%;
    border-bottom: 1px solid var(--c_element-disabledBlack)
  }
}

.loginLinkOuter {
  margin: 0.5rem 0 0;
  min-height: 2.75rem;

  width: 100%;

  &:first-child {
    margin-top: 0;
  }
}

.ssoLinkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  background: #EEEFF1;
  flex-grow: 1;
  padding: 0.25rem 1rem;
  border-radius: 62500rem;

  .ssoLink {
    svg {
      height: 2.5rem;
      width: 2.5rem;
    }

  }
}