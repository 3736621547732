.testimonials {
  margin-bottom: 4rem;

  .testimonialList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    margin: 0.25rem;

    li {
      flex: 1 1 25%;
      margin: 0.75rem;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        position: relative;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;

        &:before { 
          content: "\201c"; 
          font-size: 2em; 
          
          position: absolute;
          top: 0;
          left: 0;
        }
        &:after { 
          content: "\201d"; 
          font-size: 2em; 
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      blockquote {
        flex: 1 1 auto;
        background: var(--c_white);
        font-family: var(--f_optima);
        border-radius: 1rem;
        padding: 0.75rem;

        font-size: 0.9rem;
      }
      p {
        margin: 0.5rem 1rem;
      }
    }
  }

  @media (min-width: theme('screens.md')) {
    .testimonialList {
      flex-direction: row;
    }
  }
}
