.propertyPlan {
  --c_intro-em-green: var(--c_green);
  --c_intro-em-gold: var(--c_gold);
  --c_project_image-bg: var(--c_green-96-10);
  --c_project_image: var(--c_neutral-47);
  --c_project-column-headers: var(--c_text-secondary);
  --c_project-border: var(--c_neutral-86);

  --f_intro: var(--f_optima);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .intro {
    font-size: 1.25rem;
    font-family: var(--f_intro);
    line-height: 125%;
    margin: 2rem 0;
    text-align: center;

    @media (max-width: (theme('screens.sm') - 1)) {
      width: 100%;
    }

    .value,
    .livability {
      color: var(--c_intro-em-gold);
    }
    .revenue,
    .cost {
      color: var(--c_intro-em-green);
    }
  }

  .projects {
    display: grid;
    grid-template-columns: 2.5rem minmax(0, 1fr) 2.5rem;


    @media (min-width: theme('screens.xl')) {
      grid-template-columns: 5rem minmax(0, 1fr) 5rem;
    }

    .next,
    .previous {
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        border-radius: 50%;
        color: var(--c_neutral-70);
        border: 1px solid var(--c_neutral-86); 
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: theme('screens.xl')) {
          width: 3rem;
          height: 3rem;
        }

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .previous {
      .icon {
        transform: scaleX(-1);
      }
    }

    .noResults {
      margin: 1.5rem 0;
    }

    ul.ideas {
      display: grid;
      gap: 0.75rem;

      @media (min-width: theme('screens.xl')) {
        grid-template-columns: repeat(4, minmax(0, 25%));
      }

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      li.project {
        display: none;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        max-width: calc(100vw - 4rem);
        position: relative;
        padding: 1.5rem;
        border: 1px solid var(--c_neutral-86);
        border-radius: 2rem;

        &.primary {
          display: flex;
        }

        @media (min-width: theme('screens.xl')) {
          display: flex;
        }

        .icon {
          flex: 0 0 auto;
          box-sizing: content-box;
          width: 4rem;
          height: 3rem;
          position: relative;
          margin: 1rem 0 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .details {
          align-items: center;
          text-align: center;
          .title {
            font-size: 1.25rem;
          }
          .cost {
            font-size: 1.25rem;
          }
          .costLabel {
            color: var(--c_text-secondary);
          }
        }

        .actions {
          width: 100%;
          display: flex;
          margin-top: 1.5rem;
          text-align: left;

          .add {
            color: var(--c_black);
            display: inline-flex;
            align-content: center;
            font-weight: bold;

            .plus {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .done {
            display: flex;
            color: var(--c_neutral-86);
            align-items: center;
            margin-left: auto;
            font-weight: bold;

            .check {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        &.active {
          color: var(--c_neutral-65);

          .done {
            color: var(--c_black);
          }

          .add {
            color: var(--c_neutral-65);
          }

          .icon {
            opacity: 0.5;
          }

          .details {
            .costLabel {
              color: var(--c_neutral-65);
            }
          }
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: flex-start;
    justify-self: flex-end;
  }
}

@media (min-width: theme('screens.xs')) {
  .propertyPlan {
    .ideas {
      .ideaList {
        header {
          .cost {
            display: block;
          }
        }
        .project {
          .details {
            display: flex;
          }
        }
      }
    }
  }
}

/* Special consideration for our grid layout */
@media (min-width: 768px) {
  .propertyPlan {
    .ideas {
      .ideaList {
        header {
          .cost {
            display: none;
          }
        }
        .project {
          .details {
            display: block;
          }
        }
      }
    }
  }
}

@media (min-width: theme('screens.lg')) {
  .propertyPlan {
    .intro {
      margin: 0 2rem 2rem;
    }

    .action {
      justify-content: center;

      .cta {
        padding: 1rem 1rem;
      }
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .propertyPlan {
    .ideas {
      .ideaList {
        header {
          .cost {
            display: block;
          }
        }
        .project {
          .details {
            display: flex;
          }
        }
      }
    }
  }
}

.heading {
  display: grid;
  grid-template-columns: 40% minmax(0, 1fr);

  @media (max-width: (theme('screens.xl') - 1)) {
    display: block;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;

    @media (max-width: (theme('screens.xl') - 1)) {
      font-size: 0.9375rem;
      margin-right: 0;
    }

    .filters {
      align-items: flex-start;
      display: flex;
      gap: 0.5rem;
      color: var(--c_neutral-65);
      margin-bottom: 1.5rem;

      button.active {
        color: var(--c_black);
      }
    }
  }
}
