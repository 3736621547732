.comparables {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
  justify-content: center;

  .comparable {
    flex: 1 1 auto;
    max-width: 220px;
    padding: 0.75rem;
    
    .comparableImage {
      border-radius: 0.5rem;
      width: 100%;
      height: auto;
    }

    .comparableAddress {
      margin-top: 0.75rem;
      font-weight: bold;
    }

    .comparableValue {
      color: var(--c_green-44);
      margin-bottom: 0.5rem;
    }

    .comparableDetails {
      display: flex;

      .comparableDetail {
        display: flex;

        svg {
          margin-left: 0.5rem;
        }
      }

      .comparableDetail + .comparableDetail {
        margin-left: 1.625rem;
      }
    }
  }

  @media (min-width: theme('screens.sm')) {
    grid-template-columns: repeat(3, 1fr);
  }
}