.completeness {  
  @media (min-width: theme('screens.md')) {
    position: sticky;
    top: 3rem;
  }

  .card {
    text-align: center;
    padding: 1.5rem;
    margin-top: 0;
  }

  .helpBox {
    display: none; // Not visible in mobile
    flex-direction: column;
    align-items: center;
    width: 100%; 
    border-radius: 1.5rem;

    padding: 2rem;

    font-size: 1.125rem;
    text-align: center;

    background: var(--c_green-95);

    .advisorCta {

      border-radius: 4rem;
      margin-top: 1rem;
      padding: 0.5rem 1.5rem;

      font-weight: 300;

      background: var(--c_green);
      color: var(--c_white);
      
      &:focus {
        outline-color: var(--c_green-60);
      } 
    }

    @media (min-width: theme('screens.md')) {
      display: flex;
    }
  }
}

.avatar {
  object-fit:cover;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin: calc(-1.5rem - 40px) auto 1rem;
}

.address {
  font-size: 1.5rem;
}

.cityStateZip {
  color: var(--c_neutral-47);
}

.strength {
  .label {
    color: var(--c_neutral-47);
  }

  .level {
    font-family: var(--f_optima);
    font-size: 1.75rem;
  }
}

.steps {
  margin: 1em -1.5rem;
}

.outro {
  margin-top: 1.5rem;
  font-family: var(--f_optima);
  font-size: 1.25rem;
  text-align: left;
}

.action {
  margin-top: 0.875rem;
  text-align: left;
}

.avm {
  padding: 1.5rem;
  color: var(--c_neutral-47);
  background: var(--c_green-96);
  margin: 1.5rem -1.5rem;
  letter-spacing: -0.0125rem;
  line-height: 1.1875rem;

  .avmValue {
    font-size: 2.25rem;
    line-height: 2.6875rem;
    color: theme('colors.base.charcoal');
    
    &.dirty {
      color: theme('colors.moss');
    }
  }

  .avmDifference {
    margin-top: 1rem;

    .moneyDifference {
      color: theme('colors.moss');
      font-weight: bold;
    }
  }

  &.decrease {
    background: theme('colors.tangerine-fine');

    .moneyDifference {
      color: theme('colors.tangerine');
    }

    .avmValue.dirty {
      color: theme('colors.tangerine');
    }
  }
}

a.cta {
  display: inline-block;
  font-size: 1rem;
  color: var(--c_green);
  background: var(--c_white);
  border: 1px solid var(--c_green);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;


  &:hover {
    text-decoration: none;
    color: var(--c_green);
  }
}

.status {
  margin-top: 2.25rem;
  color: var(--c_neutral-47);

  .message {
    margin-top: -1.1875rem;
    line-height: 1.1875rem;

    &:global(.success) {
      color: theme('colors.moss');
    }

    &:global(.error) {
      color: var(--c_orange-63);
    }
  }
}
