.packages {
  --c_buy-color-1-bg: #f0f5fa;
  --c_buy-color-2-bg: #fdf9e9;
  --c_buy-color-3-bg: #f9fcf1;
  --c_buy-color-4-bg: #fbf9f2;

  --c_buy-color-1-button: #4b51c4;
  --c_buy-color-2-button: #ecb730;
  --c_buy-color-3-button: var(--c_green);
  --c_buy-color-4-button: #e0754a;

  margin: 0.75rem 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .package {
    flex: 0 1 17.5rem;
    display: flex;
    flex-direction: column;

    margin: 0.75rem;
    padding: 0.75rem;

    background: var(--c_white);
    border-radius: 1rem;

    box-shadow: 0 4px 16px 1px rgba(224, 212, 200, 0.5);

    opacity: 0;
    animation: fadeIn 200ms ease-in forwards;

    .img {
      position: relative;
      display: block;
      width: 100%;
      height: 17.5rem;

      border-radius: 0.5rem;

      background: var(--c_neutral-95);

      overflow: hidden;

      svg {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0.2;
      }
    }

    .legal {
      font-size: 0.75rem;
      color: var(--c_element-disabledBlack);

      a {
        text-decoration: underline;
      }
    }

    .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      padding: 0.75rem;

      h3 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        flex: 1 1 auto;
        font-size: 0.9rem;
        min-height: 7.5em;
        word-break: break-word;

        a {
          margin-left: 0.5em;
          text-decoration: underline;
        }
      }

      footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1rem;

        .price {
          font-family: var(--f_monospace);
          font-size: 1.5em;
          font-weight: 500;
          flex: 1 1 auto;
          margin-right: 0.1em;

          .interval {
            font-family: var(--f_open-sans);
            font-size: 0.66em;
            font-weight: 400;

            margin-left: 0.1em;

            &:before {
              content: '/';
              margin-right: 0.1em;
            }
          }
        }

        .buyButton {
          flex: 0 0 auto;

          display: block;
          background: var(--c_neutral-95);
          color: var(--c_white);
          border-color: var(--c_neutral-95);
          font-size: 1.1em;
          font-weight: 200;
          padding: 0.5em 1.25em;

          align-self: flex-end;
        }

        .purchasedMessage {
          font-size: 1.1em;
          opacity: 0.4;
        }
      }
    }

    &.removing {
      opacity: 1;
      animation: fadeOut 200ms ease-out forwards;
    }
  }

  .package1 {
    .content footer .buyButton {
      background: var(--c_buy-color-1-button);
      border-color: var(--c_buy-color-1-button);
    }
    .img {
      background: var(--c_buy-color-1-bg);
      color: var(--c_buy-color-1-button);
      svg {
        top: -19rem;
        left: -39rem;

        height: 80rem;
        width: 80rem;
      }
    }
  }
  .package2 {
    .content footer .buyButton {
      background: var(--c_buy-color-2-button);
      border-color: var(--c_buy-color-2-button);
    }
    .img {
      background: var(--c_buy-color-2-bg);
      color: var(--c_buy-color-2-button);
      svg {
        top: -7rem;
        left: -7rem;

        height: 80rem;
        width: 80rem;
      }
    }
  }
  .package3 {
    .content footer .buyButton {
      background: var(--c_buy-color-3-button);
      border-color: var(--c_buy-color-3-button);
    }
    .img {
      background: var(--c_buy-color-3-bg);
      color: var(--c_buy-color-3-button);
      svg {
        top: -0.2rem;
        left: -0.4rem;

        height: 31rem;
        width: 31rem;
      }
    }
  }
  .package4 {
    .content footer .buyButton {
      background: var(--c_buy-color-4-button);
      border-color: var(--c_buy-color-4-button);
    }
    .img {
      background: var(--c_buy-color-4-bg);
      color: var(--c_buy-color-4-button);
      svg {
        top: -6rem;
        left: -7rem;

        height: 30rem;
        width: 30rem;
      }
    }
  }
  .content footer .buyButton:disabled:not(.loading),
  .package1 .content footer .buyButton:disabled:not(.loading),
  .package2 .content footer .buyButton:disabled:not(.loading),
  .package3 .content footer .buyButton:disabled:not(.loading),
  .package4 .content footer .buyButton:disabled:not(.loading) {
    background: var(--c_neutral-95);
    border-color: var(--c_neutral-95);
    color: var(--c_neutral-100);
  }

  .fake {
    h3,
    p,
    .content footer .buyButton {
      color: transparent;
    }
    .img,
    .content footer .buyButton {
      background: linear-gradient(
        90deg,
        var(--c_neutral-95) 0%,
        var(--c_neutral-100) 30px,
        var(--c_neutral-95) 40px,
        var(--c_neutral-95) 1000px
      );
      background-size: 600% 600%;
      background-position: 600%;
      animation: shine 10s linear infinite;
    }
  }
}

@keyframes shine {
  0% {
    background-position: 600%;
  }
  100% {
    background-position: 0%;
  }
}

.detailsButtons {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  a.detailsButton {
    background: var(--c_green);
    color: var(--c_white);
    padding: 0.5em 1.25em;
    font-weight: 400;

    width: 7em;

    &.download {
      width: auto;
      background: var(--c_white);
      color: var(--c_green);
      border-color: var(--c_green);
      margin-right: auto;

      .downloadDetails {
        display: flex;
        align-content: center;

        .icon {
          margin-right: 0.5rem;
        }
      }
    }

    &.secondary {
      background: var(--c_neutral-95);
      border-color: var(--c_neutral-95);
      color: var(--c_black);
      font-weight: 500;
    }
    &:disabled {
      background: var(--c_neutral-95);
      border-color: var(--c_neutral-95);
      color: var(--c_neutral-85);
      font-weight: 400;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: theme('screens.sm')) {
  .packages {
    margin: 0.75rem -0.75rem;
  }

  .detailsButton {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: theme('screens.xl')) {
  .packages .package {
    flex: 0 1 17.5rem;
    .img {
      height: 17.5rem;
    }
  }
}
