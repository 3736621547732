.wrapper {
  min-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 6.6rem;
  position: absolute;
  padding: 2rem;
  top: 0;
  color: var(--c_green)
}

.inner {
  max-width: 42rem;
  padding: 1.5rem;
}
