
.wrapper {
  position: relative;
  
  .barWrapper {
    display: flex;
    margin: 0 calc(-0.375rem / 2);
    height: 2rem;
    align-items: center;
  
    .chunk {
      background: var(--c_neutral-86);;
      height: 0.75rem;
      width:100%;
      border-radius: 1.5rem;
      margin: 0 calc(0.375rem / 2);
     
    
      &.selected {
        background: var(--c_gold-50);
      }
    }
  }
  
  .bubble {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--c_neutral-86);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    box-shadow: 0 0 0 0.25rem var(--c_white);

    &.selected {
      background: var(--c_gold-50);;
      box-shadow: 0 2px 32px 0 var(--c_gold-50), 0 1px 6px 0 var(--c_gold-50);
    }

    svg {
      color: var(--c_white);
    }
  }
}

