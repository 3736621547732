.overview {
  .desktop {
    display: none;
  }
  .card {
    position: relative;
    padding: 1.5rem;
    border-radius: 1.5rem;    
  }
  
  .info {
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: var(--c_neutral-85);
  }
  header {
    position: relative;
    display: flex;
    flex-direction: column;

    margin-top: 0;
    margin-bottom: 1.5rem;

    .avatar {
      object-fit: cover;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      margin: -40px 0 1rem;
    }

    .address {
      font-size: 1.5rem;
    }

    .cityStateZip {
      color: var(--c_neutral-47);
    }
    .info {
      right: 0;
    }
  }  
  header.desktop {
    align-items: center;
    text-align: center;

    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }

  .line {
    display: block;
    align-items: center;

    font-size: 0.9rem;

    .labels {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      .label {
        flex: 1 1 auto;
        svg {
          display: inline-block;
          margin-left: 0.4rem;
          height: 1rem;
          width: 1rem;
        }
      }
      
      .old {
        display: none;

        font-size: 0.9rem;
        color: var(--c_neutral-47);
        text-decoration: line-through;
      }
    }
    .values {
      flex: 0 0 auto;

      .current {
        font-size: 1.5rem;
        color: Var(--c_neutral-47);
      }
      .new {
        font-size: 1.5rem;
        color: var(--c_green);
      }
      .score {
        display: inline-block;

        font-size: 1rem;
        font-family: var(--f_monospace);
        font-weight: 500;
        letter-spacing: 0.0175em;

        border-radius: 2rem;
        padding: 0.5rem 1rem;
        background: var(--c_lime);       
      }
    }

    &.scoreLine {
      margin-top: 1rem;
    }

    &.desktop {
      display: none;
    }
  }
  .stats,
  .totals {
    display: flex;

    & > div {
      flex: 1 1 50%;
    }
  }
  .totals {
    padding-top: 1rem;
  }
  footer {
    display: none; // Not visible in mobile
    flex-direction: column;
    align-items: center;

    background: var(--c_green-96);
    border-bottom-left-radius: 2.25rem;
    border-bottom-right-radius: 2.25rem;

    margin: 1rem -1.5rem -1.5rem;
    padding: 1.5rem;

    font-size: 1.1rem;
    text-align: center;

    .advisorCta {
      border-radius: 4rem;
      margin-top: 1rem;
      padding: 0.5rem 1.5rem;

      font-weight: 300;

      background: var(--c_green);
      color: var(--c_white);

      &:focus {
        outline-color: var(--c_green-60);
      }
    }
  }

  @media (min-width: theme('screens.md')) {
    // The reasoning for this extra border-radius on the bottom, is because on
    // some platforms the anti-aliasing of the rounded border can be seen
    // behind the element that is the last child in the container (and has its
    // border-radius set to 2.25). By making the radius something larger, it
    // avoids the visual 'glitch':
    .card:not(.noFooter) {
      border-bottom-left-radius: 5rem;
      border-bottom-right-radius: 5rem;
    }
    footer { display: flex; }
  }

  @media (min-width: theme('screens.lg')) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
    .card {
      border-radius: 2.25rem;
    }
    header.desktop {
      display: flex;
    }
    .line {
      display: flex;
      font-size: 1rem;
      .labels {
        .old {
          display: inline;
        }
      }
      .values {
        text-align: right;
        .old {
          display: inline;
        }
      }

      &.scoreLine {
        margin-top: 0;
      }

      & + .line {
        margin-top: 1rem;
      }
      &.desktop {
        display: flex;
      }
    }
    .stats,
    .totals {
      display: block;
    }
    .totals {
      margin-top: 1.5rem;
      border-top: 1px solid var(--c_neutral-86);
      padding-top: 1.5rem;
    }
  }
}
