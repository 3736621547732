.select {
  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--c_yellow-98);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    border: 1px solid var(--c_neutral-86);
    border-radius: 1.5rem;
    color: var(--c_neutral-4);
    height: 3rem;
    line-height: 1.25rem;
    padding-left: 1.5rem;
    padding-right: 2rem;
    text-overflow: ellipsis;

    &:focus {
      background-color: var(--c_yellow-98);
      box-shadow: none;
      border-color: var(--c_green-44);
    }
  }

  label {
    font-weight: normal;
  }
}
