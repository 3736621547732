.upload {
  border: dashed 1px var(--c_neutral-86);
  background-color: var(--c_yellow-98);
  border-radius: .25rem;
  color: var(--c_neutral-47);
  display: flex;

  &.attached {
    background: var(--c_blue-95);
    border-color: var(--c_blue-59);
    border-style: solid;
    color: var(--c_neutral-4);

    svg {
      color: var(--c_blue-59);
    }

    :global(.touchTarget) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  :global(.touchTarget) {
    padding: 1.25rem 1.875rem 1.25rem 1.25rem;
    position: relative;
    flex: 1;
    width: 100%;

    input[type=file],
    input[type=button]{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .uploading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
    }

    .documentLabel {
      display: flex;

      .fileName, .placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 2;
        width: 100%;
      }

      svg {
        margin-right: .25rem;
        width: 2em;
        flex-shrink:0;
      }

      button {
        color: var(--c_neutral-65);
        margin-right: -1.875rem;
        flex-shrink:0;
        z-index: 1;
      }

      .shortLabel {
        display: none;

        @media (max-width: (theme('screens.lg') - 1)) {
          display: inline;
        }
      }

      .longLabel {
        display: none;
        
        svg {
          display: inline;
          height: 1rem;
          margin-left: -0.3125rem;
        }

        @media (min-width: theme('screens.lg')) {
          display: inline;
        }
      }
    }
  }
}
