.renovation {
  &:not(:first-child) {
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    border-top: 1px solid var(--c_neutral-86);
  }

  display: grid;
  grid-template-columns: 1fr 14rem; 
  grid-template-rows: auto 1fr;
  gap: 0 2rem;

  .header {
    grid-column-start: 1;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;

    &.editing {
      grid-column-end: span 2;
    }

    @media (max-width: (theme('screens.md') - 1)) {
      grid-column-end: span 2;
    }

    h3.headerText {
      margin-right: 1rem;
    }

    button.editButton {
      font-size: 1rem;
      line-height: 1rem;
      padding: 0.625rem 1rem; 
      margin-left: auto;
      margin-bottom: -1rem;
      letter-spacing: -0.0175rem;
      border: none;
    }
  }

  .body {
    grid-column-start: 1;
    grid-row-start: 2;

    @media (max-width: (theme('screens.md') - 1)) {
      grid-column-end: span 2;
    }

    .bodyGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem 1.5rem;

      .emptyMessage, 
      .details {
        grid-column-end: span 2;
      }
    }
  }

  .photos {
    grid-column-start: 2;
    grid-row-end: span 2;

    @media (max-width: (theme('screens.md') - 1)) {
      grid-column-start: 1;
      grid-column-end: span 2;
      margin-top: 1rem;
    }
  }

  .save { 
    margin-top: 1.5rem;
    grid-column-end: span 2;
    display: flex;

    .close {
      display: flex;
      align-items: center;
      color: var(--c_neutral-65);
      margin-left: auto;

      :global(.close) {
        float: none;
        display: inline-block;
        margin-left: 0.25rem;
      }

      .short {
        display: none;

        @media (max-width: (theme('screens.lg') - 1)) {
          display: inline;
        }
      }

      .long {
        display: none;

        @media (min-width: theme('screens.lg')) {
          display: inline;
        }
      }
    }

    .saveButton {
      display: flex;
      gap: 1rem;
    }
  }
}

