.checklist {
  background: var(--c_gold-95);
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 3rem;

  h4 {
    font-family: var(--f_optima);
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  ol li {
    margin: 0.5rem 1rem;
    list-style-type: decimal;

    &.done {
      text-decoration: line-through;
      color: theme('colors.base.gray-three');
    }
  }
}
