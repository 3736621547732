.contractors {
  .list {
    color: var(--c_text-secondary);
    margin: 1rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, auto));

    &.noData {
      grid-template-rows: repeat(3, 2rem);
    }

    .redacted {
      display: inline-block;
      height: 1.5rem;
      background: var(--c_neutral-95);
      max-width: 100%;
    }

    .name {
      font-weight: bold;
      color: var(--c_black);
    }
  }
}

.comingSoon {
  color: var(--c_neutral-47);
  font-style: italic;
  display: block;
  font-size: 80%;

  @media (min-width: theme('screens.xl')) {
    display: inline;
    font-size: 100%;
  }
}

.contactCta {
  color: theme('colors.moss') !important;
}
