.heading {
  text-align: center;
  margin: 0 1.5rem;

  .logo {
    width: 6.5rem;
    height: 2rem;
    margin: 0 auto 1rem;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.subheading {
  margin-top: 0.25rem;
}

.dismissButton {
  align-self: flex-start;
  margin-bottom: 1rem;
}

.loadingWrapper {
  min-height: 6rem;
  position: relative;
}

.modalWrapper {
  height: 100vh;
  width: 100vw;
  background-color: var(--c_neutrals-100);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.details {
  background: var(--c_element-primaryWhite);
  margin: 1.5rem 0;
  width: 100%;
  border-radius: 3rem;
  border: 0.5px solid var(--c_neutrals-300);
  padding: 1.5rem;

  .bubblesWrapper {
    display: flex;
    justify-content: center;

    .bubbles {
      position: relative;
      display: flex;
      margin-bottom: 1rem;

      .bubble {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: var(--c_background-secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        border: 1px solid var(--c_element-primaryWhite);

        &.initials {
          color: var(--c_neutrals-500);
          margin-left: -1rem;
          z-index: 1;
        }

        &.advisor {
          color: var(--c_secondary-700);
          background: var(--c_secondary-100);
          z-index: 2;
        }
      }
    }
  }
}

.meetingTitle {
  text-align: center;
}

.meetingTime {
  text-align: center;
  margin-top: 0.125rem;
}

.divider {
  margin: 2rem 0;
}

.meetingNotes {
  margin-top: 0.25rem;
}

.container {
  padding: 1.5rem;
  font-family: var(--f_open-sans);

  &.gradient {
    background: linear-gradient(171.21deg, rgba(190, 194, 251, 0.3) 9.53%, rgba(252, 246, 242, 0) 86.2%);
  }

  .wrapper {
    width: 100%;
    margin: 0 auto;

    @media (min-width: theme('screens.sm')) {
      max-width: 32.5rem;
    }
  }
}